import React, { useContext, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import GetApp from "../GetApp/GetApp";
import VenueSearch from "../VenueListing/venueSearch";
import Popup from "../LiveScoreCardpopups/Popup";
import { isValid } from "../constants";

const NavItem = ({ text, onClick }) => (
  <div
    className="px-3 fw-600 cursor-pointer"
    style={{ fontSize: "17px" }}
    onClick={onClick}
  >
    {text}
  </div>
);

const ProfileDropdown = ({ userProfileImage, userFullName, onLogout }) => (
  <NavDropdown
    title={
      <img
        src={userProfileImage}
        style={{ maxHeight: "35px", borderRadius: "50%" }}
        className="img-fluid "
        alt="Profile"
      />
    }
    id="profile-dropdown"
    className="d-inline-block img-fluid"
  >
    {/* <NavDropdown.Item>{userFullName}</NavDropdown.Item> */}
    <NavDropdown.Item onClick={() => (window.location.href = "/user-profile")}>
      Profile Details
    </NavDropdown.Item>
    <NavDropdown.Item onClick={onLogout}>Logout</NavDropdown.Item>
  </NavDropdown>
);

const UserActions = ({
  isGuest,
  UserType,
  showGuestPage,
  showBookingsPage,
  logout,
  userLocation,
  setShow,
  userProfileImage,
  userFullName,
  pathname,
}) => (
  <>
    {!isGuest ? (
      <>
        {UserType !== "Venueowner" && UserType != "Staff" && (
          <>
            {/* <NavDropdown
                title="My Matches"
                id="basic-nav-dropdown"
                className="px-3 fw-600 cursor-pointer"
                style={{ fontSize: "17px" }}
              >
                <NavDropdown.Item
                  onClick={showGuestPage("/created-matches", "/created-matches")}
                >
                  Created Matches
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={showGuestPage("/joined-matches", "/joined-matches")}
                >
                  Joined Matches
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={showGuestPage(
                    "/match-join-request",
                    "/match-join-request"
                  )}
                >
                  Match Requests
                </NavDropdown.Item>
              </NavDropdown> */}
            <NavItem text="My Bookings" onClick={showBookingsPage} />
          </>
        )}

        {(UserType === "Venueowner" ||
          UserType === "Hybrid" ||
          UserType === "Staff" ||
          UserType === "Admin") && (
          <>
            <NavItem
              text={UserType === "Admin" ? "Venue Requests" : "My Venues"}
              onClick={() => (window.location = "/home-venue-owner")}
            />
            {(UserType === "Admin" ||
              UserType === "Hybrid" ||
              UserType === "Venueowner") && (
              <NavItem
                text="View Coupon"
                onClick={() => (window.location = "/coupon-home")}
              />
            )}
          </>
        )}
        {(UserType === "User" || UserType === "Hybrid") && (
          <>
            <NavItem
              text="Refer&Earn"
              onClick={() => (window.location = "/inviteandearn")}
            />
          </>
        )}
      </>
    ) : (
      <>
        <NavItem
          text="Book Venue"
          onClick={() => {
            if (!userLocation) {
              setShow(true);
            } else {
              window.location.href = `/venue-search/${userLocation.toLocaleLowerCase()}/sports/all`;
            }
          }}
        />
        {/* <NavItem
          text="Contact Us"
          onClick={() => (window.location = "/contact-us")}
        /> */}
      </>
    )}
    <NavItem
      text="Contact Us"
      onClick={() => (window.location = "/contact-us")}
    />
    <NavItem text="FAQ" onClick={() => (window.location = "/faq")} />
    <NavItem
      text={
        isGuest ? (
          "Login/Signup"
        ) : (
          <>
            <ProfileDropdown
              userFullName={userFullName}
              userProfileImage={userProfileImage}
              onLogout={logout}
            />
          </>
        )
      }
      onClick={isGuest ? showGuestPage("/welcome", "/welcome") : ""}
    />
  </>
);

export default function NavBarDesktop(props) {
  const UserType = window.localStorage.getItem("UserType");
  const navigate = useNavigate();
  const [isGuest, setIsGuest] = useState(false);
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const userid = window.localStorage.getItem("userid");
  const { Auth } = useContext(UserContext);
  const userLocation = window.localStorage.getItem("userLocation");

  useEffect(() => {
    setIsGuest(!userid || userid === "0" || UserType === "Guest" || !UserType);
  }, [Auth]);

  const showGuestPage = (guestPath, userPath) => () => {
    if (isGuest) {
      window.localStorage.setItem("RedirectURL", window.location.href);
      window.location.href = guestPath;
    } else {
      window.location.href = userPath;
    }
  };

  const showBookingsPage = () => {
    if (isGuest) {
      window.localStorage.setItem("RedirectURL", window.location.href);
      window.location.href = "/welcome";
    } else {
      navigate("/venue-booking-details");
    }
  };

  const loginPage = () => {
    window.location.href = "/welcome";
  };

  return (
    <div className="px-5" style={{ background: "#FFF7F74D" }}>
      <Navbar
        sticky="top"
        collapseOnSelect
        expand="lg"
        style={{ padding: "0px" }}
      >
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto my-2 my-lg-0">
            {pathname !== "/campaign" ? (
              <a href={"/home"}>
                <img
                  src="/images/cricketFlash.png"
                  style={{ height: "3.5rem", width: "8rem" }}
                  className="mt-1 mb-2 ms-0"
                />
              </a>
            ) : (
              <img
                src="/images/cricketFlash.png"
                style={{ height: "3.5rem", width: "8rem" }}
                className="mt-1 mb-2 ms-0"
              />
            )}
          </Nav>
          {pathname !== "/campaign" ? (
            <div className="centerAlign">
              <UserActions
                isGuest={isGuest}
                UserType={UserType}
                showGuestPage={showGuestPage}
                showBookingsPage={showBookingsPage}
                logout={props.logout}
                userLocation={userLocation}
                setShow={setShow}
                userProfileImage={props.userProfileImage}
                userFullName={props.userFullName}
                pathname={pathname}
              />

              {/* <NavItem
                text="Book venue"
                onClick={() =>
                  userLocation
                    ? (window.location.href = `/venue-search/${userLocation.toLowerCase()}/sports/all`)
                    : setShow(true)
                }
              /> */}
              {/* <NavItem
                text="Contact Us"
                onClick={() => (window.location.href = "/contact-us")}
              /> */}
              {/* <NavItem text="Login/Signup" onClick={loginPage} /> */}
              <GetApp header="true" />
            </div>
          ) : (
            <></>
          )}
        </Navbar.Collapse>
      </Navbar>
      <Popup show={show} onHide={() => setShow(false)}>
        <VenueSearch sport={"all"} />
      </Popup>
    </div>
  );
}
