import {
  React,
  createContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import Carousel from "react-bootstrap/Carousel";
import { useNavigate, useParams } from "react-router-dom";
import Heart from "react-heart";
import SurfaceSportsType from "./SurfaceSportsType";
import GoogleMapLocation from "./GoogleMapLocation";
import {
  GetAPI,
  PostAPI,
  CustomGetAPI,
  CustomPostAPI,
} from "../Utils/APIConnector";
import {
  convertTo12HourFormat,
  venueSportsData,
  venueAmenitiesData,
  capitalizeWord,
  formatDateTime,
} from "../Utils/common";
import VenueBooking from "./VenueBooking";
import Loader from "../Utils/Loader";
import HelmetMetaTag from "../Utils/HelmetMetaTag/HelmetMetaTag";
import NewSurfaceSportsType from "./NewSurfaceSportsType";
import Popup from "../LiveScoreCardpopups/Popup";
import { Modal } from "react-bootstrap";
import { result } from "lodash";
import Cookies from "universal-cookie";
import NewVenueBooking from "./NewVenueBooking";
import AddUserName from "../Popup/AddUserName";

export default function VenuePlace() {
  const params = useParams();
  const navigate = useNavigate();
  const [Isapp, setIsapp] = useState(false);
  const cookies = new Cookies();
  useEffect(() => {
    if (cookies.get("Isapp")) {
      setIsapp(cookies.get("Isapp"));
    }
  }, [cookies.get("Isapp")]);
  const [venueData, setVenueData] = useState({});
  const windowWidth = window.innerWidth;
  const [sportName, setSportName] = useState();
  const [showGoogleMap, setShowGoogleMap] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  // const [cardActive, setCardActive] = useState({
  //   main: true,
  //   showSurfaceSport: false,
  //   showVenueBooking: false,
  // });
  const [active, setActive] = useState(false);
  const [showAmenities, setshowAmenities] = useState(false);
  const [showSports, setshowSports] = useState(false);
  const [courtData, setCourtData] = useState();
  const [surfaceSportData, setSurfaceSportData] = useState({
    venueId: 0,
    type: "",
    id: 0,
    label: "",
  });
  const [selectedCourtIndex, setSelectedCourtIndex] = useState(null);
  const [userID, setUserID] = useState(localStorage.getItem("userid"));
  // const userID = localStorage.getItem("userid");
  const isLoggedIn = window.localStorage.getItem("UserMobileNo");
  const [showBookingDetailsPopup, setShowBookingDetailsPopup] = useState(false);
  const [couponData, setCouponData] = useState(false);

  useEffect(() => {
    if (userID && venueData && venueData?.id > 0) {
      CustomGetAPI(
        `/venue/Getavailablecodes/${localStorage.getItem("userid")}/${
          venueData?.id ?? 0
        }`
      ).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result.status === "success") {
            setCouponData(
              result?.coupons?.filter((coupon) =>
                coupon.apponly ? coupon.apponly === Isapp : true
              )
            );
          }
        }
      });
    }
  }, [Isapp, localStorage.getItem("userid"), venueData]);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    fetchCourtData();
  }, []);
  async function fetchCourtData() {
    await CustomGetAPI(
      "/Venue/NewvenuebyId/" +
        params?.id.split("-")?.join(" ") +
        "/" +
        (userID ? userID : 0)
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setIsLoading(false);
        if (result?.status === "success") {
          setCourtData(result);
          // console.log(result?.venue);
        } else {
          navigate("/not-found");
        }
      }
    });
  }

  async function fetchData() {
    await CustomGetAPI(
      "/Venue/GetvenuebyID/" +
        params?.id.split("-")?.join(" ") +
        "/" +
        (userID ? userID : 0)
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setIsLoading(false);
        if (result?.status === "Success") {
          // console.log(result?.venue);
          setVenueData(result?.venue);
          setSportName(result?.venue?.venueSports[0]?.name);
          setSurfaceSportData({
            venueId: result?.venue?.id,
            type: "sports",
            id: result?.venue?.venueSports[0]?.id,
            label: result?.venue?.venueSports[0]?.name,
          });
        } else {
          navigate("/home");
        }
      }
    });
  }
  const goBack = () => {
    let venueURL = window.localStorage.getItem("venueRedirectURL") || "/home";
    navigate(venueURL);
  };
  const handleGetDirections = (address) => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleSavedVal = async (flag) => {
    var object = { ...venueData };
    object["isSaved"] = !flag;
    setVenueData(object);
    var raw = JSON.stringify(!flag);
    window.localStorage.setItem("RedirectURL", window.location.href);
    await PostAPI(`/Venue/ManageSavedVenue/${venueData?.id}`, raw).then(
      (result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result?.status === "Success") {
            // console.log(result);
          }
        }
      }
    );
  };

  const [venuename, setvenuename] = useState(params?.id);
  const [cityname, setcityname] = useState(params?.city);

  useEffect(() => {
    if (venuename) {
      let words = venuename.split("-"); // Split the string by "-"
      let capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      ); // Capitalize the first letter of each word
      const formattedVenueName = capitalizedWords.join(" "); // Join the words with space
      setvenuename(formattedVenueName);
    }
    if (cityname) {
      let words = cityname.split("-"); // Split the string by "-"
      let capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      ); // Capitalize the first letter of each word
      const formattedVenueName = capitalizedWords.join(" "); // Join the words with space
      setcityname(formattedVenueName);
    }
  }, []);
  useEffect(() => {
    if (courtData?.data?.length) {
      setSelectedCourtIndex(0);
    }
  }, [courtData]);
  return (
    <>
      <HelmetMetaTag
        titleName={`${venuename} | ${capitalizeWord(
          params?.city,
          "-",
          " "
        )} | CricBuddy `}
        ogdesc={`Book your slot now! ${venuename} ${capitalizeWord(
          params?.city,
          "-",
          " "
        )} -  Check photos, amenities, and timings on CricBuddy.`}
        desc={`Book your slot now! ${venuename} ${capitalizeWord(
          params?.city,
          "-",
          " "
        )} -  Check photos, amenities, and timings on CricBuddy.`}
        canonical={window.location.href}
        ogtitle={`${venuename} | ${capitalizeWord(
          params?.city,
          "-",
          " "
        )} | CricBuddy `}
        ogtype="website"
        ogurl={window.location.href}
        ogimg={venueData?.venueImages ? venueData?.venueImages[0] : ""}
      />

      {isLoading ? (
        <Loader mt="15%" />
      ) : (
        <div className="px-md-5 px-2">
          <div className="row">
            <div className="col-12 ps-4">
              <img
                className="img-fluid cursor-pointer"
                src="/images/backArrow.svg"
                height={15}
                onClick={goBack}
              />
            </div>

            <div className="Container px-3">
              <div className="row mt-1 px-2">
                <div className="col-md-8 my-3">
                  <div className="position-relative">
                    <Carousel controls={true} indicators={false}>
                      {venueData?.venueImages?.map((data, i) => (
                        <Carousel.Item className="Carousel-radius" key={i}>
                          <img
                            src={data}
                            className="venuePhotos3"
                            alt="image"
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    <button
                      className="googlemapButton d-flex align-items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        handleGetDirections(venueData?.addresses);
                      }}
                    >
                      <img
                        src="/images/GoogleMap-new.svg"
                        alt=""
                        className="cursor-pointer"
                      />
                      <span
                        className="ms-1 d-none d-md-block"
                        style={{ color: "#818181", fontSize: "12px" }}
                      >
                        Open in Google Map
                      </span>
                    </button>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12 venuePlaceName mt-3">
                      <div className="row">
                        <div
                          className="col-12 d-flex justify-content-between align-items-center"
                          style={{ color: "black" }}
                        >
                          <div className="d-flex">
                            {" "}
                            <h1
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                              className="mb-0"
                            >
                              {venueData?.name}
                            </h1>
                          </div>
                          {venueData?.venueRatings && (
                            <div>
                              <span
                                style={{ fontSize: "13px", fontWeight: "400" }}
                              >
                                Ratings{" "}
                                <img
                                  className="img-fluid"
                                  style={{ height: "15px" }}
                                  src="/images/Star1.svg"
                                  alt=""
                                />{" "}
                                <b>{venueData?.venueRatings}</b>
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-12">
                          <label
                            className="colorClass"
                            style={{ fontSize: "13px" }}
                          >
                            <img
                              className="img-fluid me-1"
                              src="/images/black-clock.svg"
                              alt=""
                            />
                            {convertTo12HourFormat(venueData?.startTime) ===
                              "12:00 am" &&
                            convertTo12HourFormat(venueData?.endTime) ===
                              "12:00 am"
                              ? "24 Hours"
                              : `${convertTo12HourFormat(
                                  venueData?.startTime
                                )} - ${convertTo12HourFormat(
                                  venueData?.endTime
                                )}`}
                          </label>
                        </div>
                        <div
                          className="col-12 venueText mt-2"
                          style={{ color: "#545454" }}
                        >
                          {venueData?.addresses?.split(",").slice(1).join(",")}
                        </div>
                      </div>
                    </div>
                  </div>

                  {courtData?.offer?.length >= 1 && (
                    <div className="specialOffer p-3 mt-4">
                      <img src="/images/special-offer.svg" alt="" />
                      <span
                        className="venueText ms-2"
                        style={{ fontSize: "16px", fontWeight: "700" }}
                      >
                        SPECIAL <span style={{ color: "#A51e22" }}>OFFER</span>
                      </span>
                      <br />
                      <label className="mt-1 courtnameText ms-1">
                        {courtData?.offer[0]?.description}
                      </label>
                      <br />
                      {courtData?.offer[0]?.image && (
                        <img
                          src={courtData?.offer[0]?.image}
                          alt={courtData?.offer[0]?.description}
                          className="mt-3 specialOfferImage"
                        />
                      )}
                    </div>
                  )}

                  <div className="newdiv p-3 mt-5 d-md-block d-none">
                    <h2
                      className="venueText"
                      style={{ fontSize: "16px", fontWeight: "700" }}
                    >
                      Amenities
                    </h2>
                    <div className="surface-items mt-1">
                      {venueData?.venueAmenities?.length !== 0 ? (
                        <div className="row">
                          {venueData.venueAmenities?.map((data, index) => (
                            <div key={index} className="col-md-4 col-6">
                              <img
                                src={`/images/${venueAmenitiesData(data)}`}
                                alt=""
                                style={{ height: "15px" }}
                              />
                              <span className="ps-1">
                                {data || "No Name Available"}
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="citiesbox border-0">
                          No Amenities available
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="newdiv p-2 mt-3 d-md-block d-none">
                    <div className="row text-start   py-1 mt-3 mx-md-1">
                      <div className="col-12 ">
                        <b>Payment Policy</b>
                      </div>
                      <div className="col-12 mt-2">
                        1.To reserve your slot, simply pay a{" "}
                        {venueData?.bookingAmount}% deposit at the time of
                        booking. The remaining {100 - venueData?.bookingAmount}%
                        can be paid conveniently when you arrive at the venue.
                      </div>
                      <div className="col-12 my-2">
                        2.
                        {venueData?.cancellationTime > 0
                          ? `According to this venue owner's policy, cancellation
                        is possible up to 
                        ${
                          venueData?.cancellationTime > 48
                            ? `${venueData.cancellationTime / 24} days`
                            : `${venueData.cancellationTime} hours`
                        } 
                        hours prior to the event, in this case a 100% refund
                        will be provided to the user.`
                          : `This venue has a no-cancellation policy(You can't cancel once you've booked here)`}
                      </div>
                    </div>
                    <div className="row text-start   py-1 mt-3 mx-md-1">
                      <div className="col-12">
                        <b>Rescedule Policy</b>
                      </div>
                      <div className="col-12 mt-2">
                        1.
                        {venueData?.rescheduleCount > 0
                          ? `To reschedule the booking, the rescheduling
                              request must comply with the venue's rescheduling
                              policy, which allows for
                              ${venueData?.rescheduleCount} reschedules up to
                              ${venueData?.rescheduleHours} hours prior to the
                              event.`
                          : "Rescheduling is not possible at this venue"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-3">
                  <div className="newdiv pt-0">
                    {courtData?.data?.length !== 0 ? (
                      <>
                        <div className="courtNameDiv p-3">
                          <ul className="nav nav-underline custom-nav-underline justify-content-center align-items-center">
                            {courtData?.data?.map((data, index) => (
                              <>
                                <li
                                  key={index}
                                  className={`nav-item courtnameText`}
                                >
                                  <a
                                    className={`nav-link ${
                                      selectedCourtIndex === index
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() => setSelectedCourtIndex(index)}
                                  >
                                    {data?.courtname}
                                  </a>
                                </li>
                                {index !== courtData.data.length - 1 && (
                                  <span className="mb-1">|</span>
                                )}
                              </>
                            ))}
                          </ul>
                        </div>

                        {courtData?.data[selectedCourtIndex] && (
                          <div>
                            <div className="row mt-2 px-3">
                              <div className="col-8  d-flex mx-0 justify-content-between row">
                                {courtData?.data[
                                  selectedCourtIndex
                                ]?.sport?.map((sportData, sportIndex) => (
                                  <div
                                    key={sportIndex}
                                    className="d-flex align-items-center p-2 my-2 w-auto justify-content-center"
                                    style={{
                                      border: "1px solid #d2d2d2",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <img
                                      src={`/images/${venueSportsData(
                                        sportData?.name
                                      )}`}
                                      alt={sportData?.name}
                                      className="sportsAmenities me-1 img-fluid fs-12"
                                    />
                                    <span className="courtnameText fw-400 fs-12">
                                      {sportData?.name}
                                    </span>
                                  </div>
                                ))}
                              </div>
                              <div className="col-4 p-0">
                                <div className="row">
                                  <div className="col-12">
                                    <span className="courtnameText fw-700">
                                      Max Capacity
                                    </span>
                                  </div>
                                  <div className="col-12 ">
                                    <label className="courtnameText fw-400 mb-3">
                                      (
                                      {
                                        courtData?.data[selectedCourtIndex]
                                          .maxcapacity
                                      }
                                      <img src="/images/peopleicon.svg" />)
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-2 px-3">
                              <div className="col-12 col-md-12">
                                {courtData?.data[
                                  selectedCourtIndex
                                ]?.weekDay?.map((weekday, i) => (
                                  <div className="" key={i}>
                                    {weekday?.data[0]?.rehearsal && (
                                      <>
                                        <span className="courtnameText fw-700">
                                          <b className="text-danger">
                                            Rehearsal Price
                                          </b>
                                        </span>
                                        <br />
                                      </>
                                    )}

                                    <span className="courtnameText fw-700">
                                      {weekday?.weekDays}
                                    </span>
                                    <br />
                                    {weekday?.data?.map((item, i) => {
                                      return (
                                        <div className="mt-2" key={item?.id}>
                                          {courtData?.data[selectedCourtIndex]
                                            ?.sport[0]?.name ===
                                          "Go-Karting" ? (
                                            <>
                                              {item?.duration1 != null && (
                                                <>
                                                  <label className="courtnameText fw-400 mb-3">
                                                    5 Min
                                                  </label>
                                                  <b className="courtPrice float-end">
                                                    ₹ {item?.duration1}
                                                  </b>
                                                  <br />
                                                </>
                                              )}

                                              {item?.duration2 != null && (
                                                <>
                                                  <label className="courtnameText fw-400 mb-3">
                                                    10 Min
                                                  </label>
                                                  <b className="courtPrice float-end">
                                                    ₹ {item?.duration2}
                                                  </b>
                                                  <br />
                                                </>
                                              )}
                                              {item?.duration3 != null && (
                                                <>
                                                  <label className="courtnameText fw-400 mb-3">
                                                    15 Min
                                                  </label>
                                                  <b className="courtPrice float-end">
                                                    ₹ {item?.duration3}
                                                  </b>
                                                  <br />
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <label className="courtnameText fw-400 mb-3">
                                                (
                                                {convertTo12HourFormat(
                                                  item?.startTime
                                                ) === "12:00 am" &&
                                                convertTo12HourFormat(
                                                  item?.endTime
                                                ) === "12:00 am"
                                                  ? "24 Hours"
                                                  : `${convertTo12HourFormat(
                                                      item?.startTime
                                                    )} - ${convertTo12HourFormat(
                                                      item?.endTime
                                                    )}`}
                                                )
                                              </label>
                                              <b className="courtPrice float-end">
                                                ₹ {item?.amount}/hr
                                              </b>
                                            </>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                ))}
                              </div>
                              {courtData?.data[selectedCourtIndex]
                                ?.extrahourecharge > 0 && (
                                <div className="col-12">
                                  <>
                                    <b className="courtnameText mb-3">
                                      Extra Hour Rate
                                    </b>
                                    <b className="courtPrice float-end">
                                      ₹{" "}
                                      {
                                        courtData?.data[selectedCourtIndex]
                                          ?.extrahourecharge
                                      }
                                      /hr
                                    </b>
                                  </>
                                </div>
                              )}
                            </div>

                            <div className="Container my-3">
                              <div className="col-5 m-auto">
                                <button
                                  className="booknowButton booking w-100"
                                  onClick={() => {
                                    setShow(true);
                                  }}
                                >
                                  <img
                                    className="me-2"
                                    src="/images/booking.svg"
                                    alt="book"
                                  />
                                  {venueData?.isAcrive === 4
                                    ? "Book Now"
                                    : venueData?.isAcrive === 5
                                    ? "Book Now"
                                    : venueData?.isAcrive === 6
                                    ? "Inquire Now"
                                    : null}
                                </button>
                              </div>
                              {venueData?.disableDesc && (
                                <p className="text-danger px-2 text-justify mt-1">
                                  Bookings are not available from{" "}
                                  {venueData?.disablestartdate} to{" "}
                                  {venueData?.disableenddate} due to{" "}
                                  {venueData?.disableDesc}.
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="citiesbox border-0">
                        No Surface Type available
                      </div>
                    )}
                  </div>
                  {windowWidth <= 900 && venueData?.id != 103 ? (
                    <div className="px-3 my-3">
                      <div className="row discountWrapper">
                        <div className="col-8 d-flex align-items-center px-2 py-3">
                          <img
                            src="/images/disount-img.svg"
                            alt="disountimg"
                            className="me-2"
                            style={{ height: "20px" }}
                          />
                          <label
                            className="fw-normal text-white Montserrat"
                            style={{ fontSize: "12px" }}
                          >
                            Get <span className="fw-bold">10% OFF</span> ON
                            First VENUE BOOKING <br />{" "}
                            <small>(App Exclusive)</small>
                          </label>
                        </div>
                        <div className="col-4">
                          <img
                            src="/images/girl-basketball.svg"
                            alt="girl-basketball"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    windowWidth <= 900 && (
                      <div className="px-3 my-3">
                        <div className="row discountWrapper fw-normal text-white Montserrat p-3">
                          CBFIRST10 coupon is not applicable to this venue
                        </div>
                      </div>
                    )
                  )}
                  <div className="newdiv p-3 mt-4">
                    <h2 style={{ fontSize: "16px", fontWeight: "700" }}>
                      About Venue
                    </h2>
                    <span className="courtnameText fw-400">
                      {venueData?.description}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-8 px-2 d-md-none d-block">
                <div className="newdiv p-3 mt-2">
                  <h2
                    className="venueText"
                    style={{ fontSize: "16px", fontWeight: "700" }}
                  >
                    Amenities
                  </h2>
                  <div className="surface-items mt-1">
                    {venueData?.venueAmenities?.length !== 0 ? (
                      <div className="row">
                        {venueData.venueAmenities?.map((data, index) => (
                          <div key={index} className="col-md-4 col-6">
                            <img
                              src={`/images/${venueAmenitiesData(data)}`}
                              alt=""
                              style={{ height: "15px" }}
                            />
                            <span className="ps-1">
                              {data || "No Name Available"}
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="citiesbox border-0">
                        No Amenities available
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-8 px-2 d-md-none d-block">
                <div className="newdiv p-3 pt-md-3 pt-1 mt-3">
                  <div className="row text-start   py-1 mt-3 mx-md-1">
                    <div className="col-12 ">
                      <b>Payment Policy</b>
                    </div>
                    <div className="col-12 mt-2">
                      1.To reserve your slot, simply pay a{" "}
                      {venueData?.bookingAmount}% deposit at the time of
                      booking. The remaining {100 - venueData?.bookingAmount}%
                      can be paid conveniently when you arrive at the venue.
                    </div>
                    <div className="col-12 my-2">
                      2.{" "}
                      {venueData?.cancellationTime > 0
                        ? `According to this venue owner's policy, cancellation
                        is possible up to 
                        ${
                          venueData?.cancellationTime > 48
                            ? `${venueData.cancellationTime / 24} days`
                            : `${venueData.cancellationTime} hours`
                        } 
                        hours prior to the event, in this case a 100% refund
                        will be provided to the user.`
                        : `This venue has a no-cancellation policy(You can't cancel once you've booked here)`}
                    </div>
                  </div>
                  <div className="row text-start   py-1 mt-3 mx-md-1">
                    <div className="col-12">
                      <b>Rescedule Policy</b>
                    </div>
                    <div className="col-12 mt-2">
                      1.
                      {venueData?.rescheduleCount > 0
                        ? `To reschedule the booking, the rescheduling
                              request must comply with the venue's rescheduling
                              policy, which allows for
                              ${venueData?.rescheduleCount} reschedules up to
                              ${venueData?.rescheduleHours} hours prior to the
                              event.`
                        : "Rescheduling is not possible at this venue"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {cardActive?.showSurfaceSport && (
            <div className="Container mt-5">
              <SurfaceSportsType surfaceSportData={surfaceSportData} />
            </div>
          )} */}
        </div>
      )}
      <Popup show={show} venueflag={1} onHide={() => setShow(false)}>
        <div className="Container mt-2 ">
          <NewVenueBooking
            venueData={venueData}
            surfaceSportData={surfaceSportData}
            setSurfaceSportData={setSurfaceSportData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setShow={setShow}
            couponsData={couponData}
            courtData={courtData}
          />
        </div>
      </Popup>
    </>
  );
}
