import * as React from "react";
import "../css/common.css";
import { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import {
  BattingStyle,
  BowlingStyle,
  ConvertDate,
  isValid,
  PlayRole,
} from "./constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import Loader from "./Utils/Loader";
import ProcessBar from "./Utils/ProcessBar/ProcessBar";
import {
  CustomGetAPI,
  CustomPostAPI,
  GetAPI,
  PutAPI,
} from "./Utils/APIConnector";
import { CheckValidation, compressImage, validateEmail } from "./Utils/common";
import Swal from "sweetalert2";
var typingTimer;
var doneTypingInterval = 1000;

export function Profile() {
  const [state, setState] = useState({});
  const [isEdit, setIsEdit] = useState("0");
  const [userdata, setUserData] = useState("");
  const [accountData, setAccountData] = useState("");
  const [accountDetails, setAccountDetails] = useState({
    accNo: "",
    ifscCode: "",
    accHolderName: "",
    address: "",
  });
  const [errors, setErrors] = useState({
    accNo: "",
    ifscCode: "",
  });
  const founduser = parseInt(window.localStorage.getItem("userid"));
  var userid = JSON.parse(founduser);
  const [location, setLocation] = useState("");
  const [blankLocation, setBlankLocation] = useState("");
  const [cityArea, setResult] = useState([]);
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [playerroleiddropdown, setPlayerRoleId] = useState("");
  const [battingstyledropdown, setBattingStyle] = useState("");
  const [bowlingstyledropdown, setBowlingStyle] = useState("");
  const [finalImage, setFinalImage] = useState("");

  const [blankRole, setBlankRole] = useState("");
  const [blankBatting, setBlankBatting] = useState("");
  const [blankBowling, setBlankBowling] = useState("");
  const [isimagechange, setisimagechange] = useState(false);
  const [profileStatus, setProfileStatus] = useState("");
  const UserType = window.localStorage.getItem("UserType");

  var battingstyleUser = window.localStorage.getItem("Battingstyle");
  useEffect(() => {
    if (!isValid(battingstyleUser)) {
      BattingStyle();
    }
  }, [battingstyleUser]);
  battingstyleUser = window.localStorage.getItem("Battingstyle");
  const battingstyle = JSON.parse(battingstyleUser);

  // bowlingstyle Dropdown
  var bowlingstyleUser = window.localStorage.getItem("Bowlingstyle");
  useEffect(() => {
    if (!isValid(bowlingstyleUser)) {
      BowlingStyle();
    }
  }, [bowlingstyleUser]);

  bowlingstyleUser = window.localStorage.getItem("Bowlingstyle");
  const bowlingstyle = JSON.parse(bowlingstyleUser);

  // playroleuser dropdown
  var playroleUser = window.localStorage.getItem("playrole");
  useEffect(() => {
    if (!isValid(playroleUser)) {
      PlayRole();
    }
  }, [playroleUser]);
  playroleUser = window.localStorage.getItem("playrole");
  const playrolestyle = JSON.parse(playroleUser);
  const wrapperRef = useRef(null);
  var Progressbarvalue = window.localStorage.getItem("UserProfileStatus");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    data();
    return () => {
      setState({});
    };
  }, []);
  const data = async () => {
    await CustomGetAPI("/User/GetAllUser/" + userid + "/" + true).then(
      (result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
          setLoading(false);
        } else {
          var result = JSON.parse(result);
          if (
            result.userDetails.area === null ||
            result.userDetails.area === ""
          ) {
            var locationDt = result.userDetails.city;
          } else {
            var locationDt =
              result.userDetails.area + ", " + result.userDetails.city;
          }
          setLocation(locationDt);
          setUserData(result.userDetails);
          setAccountData(result?.accountdetails);
          setAccountDetails({
            accNo: result?.accountdetails?.accountno,
            ifscCode: result?.accountdetails?.ifsc,
            accHolderName: result?.accountdetails?.accountname,
            address: result?.accountdetails?.address,
          });
          setFName(result.userDetails.firstName);
          setLName(result.userDetails.lastName);
          setEmail(result.userDetails.email);
          if (result.userDetails.dob != null) {
            setDob(result.userDetails.dob.split("T")[0]);
          }
          setPlayerRoleId(result.userDetails.playerRoleId);
          setBattingStyle(result.userDetails.battingstyleID);
          setBowlingStyle(result.userDetails.bowlerstyleID);
          if (
            result.userDetails.profileImage === "" ||
            result.userDetails.profileImage === undefined
          ) {
            setFinalImage("");
          } else {
            setFinalImage(result.userDetails.profileImage);
          }
          setProfileStatus(result.progressbarValue);
          setLoading(false);
        }
      }
    );
  };
  // battingstyle dropdown

  const selectAreaCity = (event) => {
    $("#areacitysuggestion").val("");
    $("#areacitysuggestion").attr("cityid", "");
    $("#areacitysuggestion").attr("areaid", "");

    $("#areacitysuggestion").val(event.target.innerHTML);
    setLocation(event.target.innerHTML);
    if (event.target.attributes[2].value != "0") {
      $("#areacitysuggestion").attr("areaid", event.target.attributes[2].value);
    }
    $("#areacitysuggestion").attr("cityid", event.target.attributes[1].value);
    $(".suggBox").addClass("d-none");
  };
  const suggKeyDown = () => {
    $(".suggBox").removeClass("d-none");
    $(".suggBox").addClass("d-block");
    clearTimeout(typingTimer);
  };
  const suggKeyup = () => {
    $(".suggBox").removeClass("d-none");
    $(".suggBox").addClass("d-block");
    clearTimeout(typingTimer);
    var element = $(this);
    typingTimer = setTimeout(function () {
      doneTyping(element);
    }, doneTypingInterval);
  };
  const doneTyping = async () => {
    if (location === "") {
      setBlankLocation("Location is required!!");
    } else {
      setBlankLocation("");

      await CustomGetAPI("/User/GetLocation/" + location).then((result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
          setLoading(false);
        } else {
          result = JSON.parse(result);
          $(".suggBox").addClass("d-block");
          var sugData = result.areacity;
          var sugArray = [];
          for (var i = 0; i < sugData.length; i++) {
            if (sugData[i].areaID === 0) {
              sugArray.push(
                <p
                  className="areacitydiv my-1 ps-2"
                  onClick={selectAreaCity}
                  cityid={sugData[i].cityID}
                  areaid={sugData[i].areaID}
                >
                  {sugData[i].cityName}
                </p>
              );
            } else {
              sugArray.push(
                <p
                  className="areacitydiv my-1 ps-2"
                  onClick={selectAreaCity}
                  cityid={sugData[i].cityID}
                  areaid={sugData[i].areaID}
                >
                  {sugData[i].areaName}, {sugData[i].cityName}
                </p>
              );
            }
          }
          setResult(sugArray);
        }
      });
    }
  };

  const ValidateEmail = (e) => {
    setIsEdit("1");
    setEmail(e.target.value);
    // const emailIsValid = validateEmail(e.target.value);
    // if (!emailIsValid) {
    //   $(".emailError").text("*Invalid Email Format");
    // } else {
    //   $(".emailError").text("");
    // }
  };
  const updateProfile = async () => {
    var isFormValid = CheckValidation({ formID: "profilePopup" });
    if (!isFormValid) {
      window.scroll(0, 0);
      return;
    }
    var playroleid = parseInt($("#playerroleid option:selected").val());
    var batstyle = parseInt($("#battingstyleId option:selected").val());
    var bowlstyle = parseInt($("#bowlingstyleId option:selected").val());
    var cityid = parseInt($("#areacitysuggestion").attr("cityid"));
    var areaid = parseInt($("#areacitysuggestion").attr("areaid")) || null;
    var baseImage = localStorage.getItem("fileBase64");
    var parseImg;
    if (baseImage != undefined && baseImage != "" && baseImage != null) {
      parseImg = JSON.parse(baseImage);
      // parseImg = parseImg.split(",");
    }
    var sporttypeId = 2;
    var gender;
    if ($("#maleCheck").is(":checked")) {
      gender = 1;
    } else if ($("#femaleCheck").is(":checked")) {
      gender = 2;
    }

    var temp = 0;

    if (location === "") {
      setBlankLocation("Location is required!!");
      setLoading(false);
    } else {
      setBlankLocation("");
      temp++;
    }

    if (playroleid === 0) {
      setBlankRole("");
      // setLoading(false);
      temp++;
    } else {
      setBlankRole("");
      temp++;
    }

    if (batstyle === 0) {
      setBlankBatting("");
      // setLoading(false);
      temp++;
    } else {
      setBlankBatting("");
      temp++;
    }

    if (bowlstyle === 0) {
      setBlankBowling("");
      // setLoading(false);
      temp++;
    } else {
      setBlankBowling("");
      temp++;
    }
    const accnovalid = validateAccNo(accountDetails?.accNo);
    if (accnovalid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["accNo"]: "",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["accNo"]: "Invalid Account Number*",
      }));
    }
    const ifscvalid = validateIfsc(accountDetails?.ifscCode);
    if (ifscvalid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["ifscCode"]: "",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["ifscCode"]: "Invalid Ifsc Code*",
      }));
    }
    const emailIsValid = validateEmail(email);
    if (!emailIsValid) {
      $(".emailError").text("*Invalid Email Format");
    } else {
      $(".emailError").text("");
    }
    if (temp >= 4 && emailIsValid && accnovalid && ifscvalid) {
      setLoading(true);
      var raw = JSON.stringify({
        FirstName: fname,
        LastName: lname,
        Email: email,
        CityId: cityid,
        AreaId: areaid,
        PlayerRoleId: playroleid,
        SportTypeId: sporttypeId,
        BattingstyleId: batstyle,
        BowlerstyleId: bowlstyle,
        Dob: dob,
        GenderId: gender,
        ProfileImage: isimagechange ? parseImg : "",
        Accountno: accountDetails?.accNo,
        Ifsc: accountDetails?.ifscCode,
        Accountname: accountDetails?.accHolderName,
        Address: accountDetails?.address,
        deposit: userdata?.deposit,
        reminder: userdata?.reminder,
        alertUser: userdata?.alertUser,
      });

      await PutAPI("/User/UpdateUser/" + userid, raw).then((result) => {
        if (result === undefined || result === "") {
          // alert("Something went wrong");
          // setLoading(false);
        } else {
          result = JSON.parse(result);
          if (result.status === "Success") {
            setIsEdit("1");
            window.localStorage.setItem(
              "UserProfileStatus",
              result.progressbarValue
            );

            window.localStorage.setItem(
              "UserProfileImage",
              result.userData.profileImage
            );

            window.localStorage.setItem(
              "UserName",
              result.userData.firstName
                ? result.userData.firstName
                : "" + " " + result.userData.lastName
                ? result.userData.lastName
                : ""
            );

            window.localStorage.setItem("phoneno", result.userData.phoneNumber);

            setTimeout(function () {
              window.location = "/user-profile";
            }, 500);
          } else if (
            result.status === "Error" &&
            result.message === "email already exist"
          ) {
            setLoading(false);
            $(".emailError").text(
              "*Please use other email this email already exists"
            );
          } else {
            alert(result.message);
            setLoading(false);
          }
        }
      });
    } else {
      $(".emailError").text("*Invalid Email Format");
      setLoading(false);
    }
  };

  const imageUpload = async (e) => {
    setisimagechange(true);
    const file = e.target.files[0];
    let compressedFile = await compressImage(file);
    getBase64(compressedFile).then((base64) => {
      localStorage.setItem("fileBase64", JSON.stringify(base64));
      setFinalImage(URL.createObjectURL(e.target.files[0]));
    });
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  const dropdownCompulsory = () => {
    var playroleid = parseInt($("#playerroleid option:selected").val());
    var batstyle = parseInt($("#battingstyleId option:selected").val());
    var bowlstyle = parseInt($("#bowlingstyleId option:selected").val());
    if (playroleid === 0) {
      setBlankRole("");
    } else {
      setBlankRole("");
    }

    if (batstyle === 0) {
      setBlankBatting("");
    } else {
      setBlankBatting("");
    }

    if (bowlstyle === 0) {
      setBlankBowling("");
    } else {
      setBlankBowling("");
    }
  };

  const handleAccountDetails = (e) => {
    const { name, value } = e.target;
    setAccountDetails({
      ...accountDetails,
      [name]: value,
    });
  };

  // const handleBlur = (e) => {
  //   const { name, value } = e.target;
  //   let errorMessage = "";
  //   if (name === "accNo") {
  //     errorMessage = validateAccNo(value);
  //   } else if (name === "ifscCode") {
  //     errorMessage = validateIfsc(value);
  //   }
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: errorMessage,
  //   }));
  // };

  const validateAccNo = (accNo) => {
    if (accNo === "" || accNo === null) {
      return true;
    }
    if (!/^\d{9,18}$/.test(accNo)) {
      return false;
    }
    return true;
  };
  const validateIfsc = (ifscCode) => {
    if (ifscCode === "" || ifscCode === null) {
      return true;
    }
    if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifscCode)) {
      return false;
    }
    return true;
  };
  const cookies = new Cookies();
  const DeleteUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await CustomPostAPI("/auth/DisableUser/" + userid).then((result) => {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            // console.log(result);
            if (result === "success") {
              Swal.fire({
                title: "Deleted!",
                text: "Your account has been deleted.",
                icon: "success",
              }).then((result) => {
                localStorage.clear();
                cookies.remove("AuthToken", { path: "/" });
                window.location.href = "/home";
              });
            }
          }
        });
      }
    });
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="" style={{ backgroundColor: "white" }}>
          <div className="row ">
            <div className="col-12 pt-2">
              <a href={"/home"}>
                <img src="./images/backArrow.svg" />
              </a>
            </div>
            <div className="col-12 pt-2 text-center">
              <label htmlFor="upload-button" className="profileImageDiv">
                {finalImage === "" ? (
                  <img src="./images/dummyavtar.png" className="profileImage" />
                ) : (
                  <img src={finalImage} alt="dummy" className="profileImage" />
                )}
              </label>
              <input
                type="file"
                accept="image/x-png,image/jpeg"
                id="upload-button"
                className="d-none"
                onChange={imageUpload}
              />
            </div>
          </div>
          {/* {parseInt(profileStatus) === 100 ? null : (
            <>
              <div className="row px-2 pt-3 text-center">
                <span>COMPLETE YOUR PROFILE</span>
                <div className="pb-0">
                  <ProcessBar Percentage={profileStatus}></ProcessBar>
                </div>
                <div style={{ fontSize: "10px", textAlign: "center" }}>
                  {Progressbarvalue} %{" "}
                </div>
              </div>
              <hr />
            </>
          )} */}
          <div className="row px-2 pt-5" id="profilePopup">
            <div className="col-6 parent">
              <input
                type="text"
                className="form-control profileformControl"
                placeholder="First Name"
                value={fname}
                required
                onChange={(e) => setFName(e.target.value)}
              />
              <span className="form-text invalid-feedback">
                * This field is Required!
              </span>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control profileformControl"
                placeholder="Last Name"
                value={lname}
                onChange={(e) => setLName(e.target.value)}
              />
            </div>
          </div>
          <div className="row px-2 pt-3">
            {UserType != "Venueowner" || UserType != "Hybrid" ? (
              <>
                <div className="col-6">
                  <DatePicker
                    showIcon
                    className="form-control profileformControl"
                    icon="fa fa-calendar"
                    selected={dob ? new Date(dob) : null}
                    onChange={(e) => setDob(e)}
                    placeholderText="DOB"
                    // closeOnScroll={true}
                  />
                  {/* <input
                type="date"
                className="form-control profileformControl"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                placeholder="DOB"
              /> */}
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="col-6">
              <input
                type="text"
                className="form-control profileformControl"
                placeholder="Contact Number"
                value={userdata.phone_Number}
                readOnly
              />
            </div>
          </div>
          <div className="px-2 mt-3">
            <div
              className="centerAlign form-control"
              style={{ display: "flex", backgroundColor: "#E8E8E8" }}
            >
              <div className="col-3">
                <label className="clsTxtColor">Gender :</label>
              </div>
              <div className="col-9">
                <div className="row">
                  <div className="col-6">
                    <input
                      type="radio"
                      id="maleCheck"
                      className="form-check-input me-1"
                      defaultChecked={userdata.gender === 1 ? "checked" : null}
                      name="genderCheck"
                    />
                    <label>Male</label>
                  </div>
                  <div className="col-6">
                    <input
                      type="radio"
                      id="femaleCheck"
                      className="form-check-input me-1"
                      defaultChecked={userdata.gender === 2 ? "checked" : null}
                      name="genderCheck"
                    />
                    <label>Female</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 px-2 pt-3">
            <input
              type="email"
              className="form-control  profileformControl"
              placeholder="Email Id"
              value={email === null ? "" : email}
              onChange={(e) => ValidateEmail(e)}
            />
            <small className="emailError text-danger"></small>
          </div>
          {UserType != "Venueowner" || UserType != "Hybrid" ? (
            <>
              {" "}
              <div className="col-12 px-2 pt-3">
                <input
                  type="text"
                  autoComplete="new-password"
                  className="form-control profileformControl"
                  id="areacitysuggestion"
                  areaid={userdata.areaId}
                  cityid={userdata.cityId}
                  placeholder="Location"
                  value={location}
                  onKeyUp={suggKeyup}
                  onKeyDown={suggKeyDown}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <div className="suggBox mx-3" ref={wrapperRef}>
                  {cityArea}
                </div>
                <span style={{ color: "red" }}>{blankLocation}</span>
              </div>
            </>
          ) : (
            <></>
          )}

          {UserType !== "Venueowner" && UserType !== "Admin" && (
            <>
              <div className="col-12 px-2 pt-3">
                <select
                  className="form-select"
                  id="playerroleid"
                  aria-label=" select example"
                  onChange={dropdownCompulsory}
                  defaultValue={
                    playerroleiddropdown === "" && playerroleiddropdown === null
                      ? "0"
                      : playerroleiddropdown
                  }
                >
                  <option value="0" className="txtGray">
                    Role
                  </option>
                  {playrolestyle?.map((item, i) => {
                    var returData;
                    returData = (
                      <option key={i} value={item.id}>
                        {item.value}
                      </option>
                    );
                    return returData;
                  })}
                </select>
                <span style={{ color: "red" }}>{blankRole}</span>
              </div>
              <div className="col-12 px-2 pt-3">
                <select
                  className="form-select"
                  id="battingstyleId"
                  aria-label=" select example"
                  onChange={dropdownCompulsory}
                  defaultValue={
                    battingstyledropdown === "" && battingstyledropdown === null
                      ? "0"
                      : battingstyledropdown
                  }
                >
                  <option value="0" className="txtGray">
                    Batting Style
                  </option>
                  {battingstyle.map((item, i) => {
                    var returData;
                    returData = (
                      <option key={i} value={item.value}>
                        {item.name}
                      </option>
                    );
                    return returData;
                  })}
                </select>
                <span style={{ color: "red" }}>{blankBatting}</span>
              </div>
              <div className="col-12 px-2 pt-3">
                <select
                  className="form-select"
                  id="bowlingstyleId"
                  aria-label=" select example"
                  onChange={dropdownCompulsory}
                  defaultValue={
                    bowlingstyledropdown === "" && bowlingstyledropdown === null
                      ? "0"
                      : bowlingstyledropdown
                  }
                >
                  <option value="0" className="txtGray">
                    Bowling Style
                  </option>
                  {bowlingstyle.map((item, i) => {
                    var returData;
                    returData = (
                      <option key={i} value={item.value}>
                        {item.name}
                      </option>
                    );
                    return returData;
                  })}
                </select>
                <span style={{ color: "red" }}>{blankBowling}</span>
              </div>
            </>
          )}

          {UserType !== "User" && UserType !== "Admin" && (
            <>
              <div className="col-12 px-2 mt-5">
                <div className="row">
                  <div className="col-8">
                    <b>Customer booking deposit percentage:</b>
                  </div>
                  <div className="col-4 text-center">
                    <input
                      type="number"
                      name="bookingAmount"
                      className="bookingAmountInput"
                      placeholder="20%"
                      value={userdata?.deposit}
                      onChange={(e) =>
                        setUserData((prev) => ({
                          ...prev,
                          deposit: e.target.value,
                        }))
                      }
                      id=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 px-2 mt-3">
                <div className="row">
                  <div className="col-8">
                    <b>
                      Send a WhatsApp alert when, booking a slot for the
                      customer.
                    </b>
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={userdata?.alertUser}
                        onChange={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            alertUser: !userdata?.alertUser,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 px-2 mt-3">
                <div className="row">
                  <div className="col-8">
                    <b>Remind customers 1 hour before booking.</b>
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={userdata?.reminder}
                        onChange={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            reminder: !userdata?.reminder,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="col-12 text-center pt-4 mb-4">
            <button
              type="submit"
              className="loginbtn col-6"
              onClick={updateProfile}
            >
              UPDATE
            </button>
          </div>
          <div className="row  px-2">
            <div
              className="col-12 text-center delete-account"
              onClick={() => {
                DeleteUser();
              }}
            >
              Delete Account
            </div>
          </div>
        </div>
      )}
    </>
  );
}
