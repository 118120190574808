import React, { useEffect, useState } from "react";
import { CustomPostAPI } from "../Utils/APIConnector";
import {
  areAllWeekdaysCovered,
  CheckValidation,
  convertTo12HourFormat,
} from "../Utils/common";
import Select from "react-select";
import WeekDays from "../VenueInformation/PricingDetails/WeekDays";
import Popup from "../LiveScoreCardpopups/Popup";
import Loader from "../Utils/Loader";
import $ from "jquery";

export default function EditVenuePage2({
  venueId,
  setSelectedPage,
  setIsMainPage,
}) {
  const [isLoading, SetIsLoading] = useState(true);
  const [sports, SetSports] = useState();
  const [surfaces, SetSurfaces] = useState();
  const [category, SetCategory] = useState();
  const [state, SetState] = useState({ StartTime: null, EndTime: null });
  const [sportsFacility, SetSportsFacility] = useState();
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [selectedIndex, setselectedIndex] = useState("");
  const [days, setDays] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });
  const [daysToShow, setDaysToShow] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  function isAllDataBlank(data) {
    for (const item of data) {
      if (item?.amount.trim() !== "" && item?.amount.trim() !== "0") {
        return false;
      }
    }
    return true;
  }

  const handleSubmit = async () => {
    const isFormValid = CheckValidation({ formID: "AddSurface" });
    let hasErrors = false;
    let isValidSelect = false;
    let newExpandedIndex = null;
    let pricingAdded = true;
    let newErrorMessages = [...errorMessages];
    for (let i = 0; i < sportsFacility.length; i++) {
      const facility = sportsFacility[i];

      if (
        facility.weekDay.length <= 1 &&
        isAllDataBlank(facility?.weekDay[0]?.data)
      ) {
        pricingAdded = false;
        newErrorMessages[i] = "Please add at least one pricing.";
        newExpandedIndex = i;
        break;
      } else {
        newErrorMessages[i] = "";
      }
      if (facility.sports.length === 0) {
        $(`.sport-error-${i}`).text("* This field is Required!");
        isValidSelect = true;
      } else {
        $(`.sport-error-${i}`).text("");
      }

      const x = sportsFacility[i];
      if (
        Object.entries(x).some(
          ([key, value]) =>
            !["extrahourprice", "rehearsalprice", "venuesportId"].includes(
              key
            ) &&
            (value === "" ||
              value === null ||
              value === undefined ||
              (Array.isArray(value) && value.length === 0))
        )
      ) {
        const emptyAcc = document.getElementById(`heading-${i}`);
        const accordionItem = emptyAcc.closest(".accordion-item");
        if (accordionItem) {
          accordionItem.style.border = "1px solid red";
        }

        setExpandedIndex(i);

        hasErrors = true;
      } else {
        const emptyAcc = document.getElementById(`heading-${i}`);
        const accordionItem = emptyAcc.closest(".accordion-item");
        accordionItem.style.border = "none";
      }
    }

    if (!pricingAdded) {
      setErrorMessages(newErrorMessages);
      setExpandedIndex(newExpandedIndex);
      return;
    } else {
      setErrorMessages([]);
    }

    if (isFormValid && !hasErrors && !isValidSelect) {
      SetIsLoading(true);
      const raw = JSON.stringify({
        stage: 2,
        Userid: window.localStorage.getItem("userid"),
        VenueId: venueId,
        SportsFacilities: sportsFacility,
      });
      await CustomPostAPI("/Venue/Editvenue", raw).then((result) => {
        if (result === undefined || result === "") {
          // alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result.status === "Success") {
            window.location.reload();
          }
        }
      });
      SetIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedIndex > 0) {
      let weekDaysToExclude = [];

      sportsFacility[selectedIndex]?.weekDay?.forEach((item) => {
        if (item.weekDays) {
          weekDaysToExclude = weekDaysToExclude.concat(
            item.weekDays.split(",").map((day) => day.trim())
          );
        }
      });

      const filteredDays = Object.keys(days).filter(
        (day) => !weekDaysToExclude.includes(day)
      );
      setDaysToShow(filteredDays);
    }
  }, []);

  var listData = [
    {
      venuesportId: null,
      surfaceType: category === "Sports" ? "" : 10,
      courtname: "",
      courtCount: "1",
      maxCapacity: "",
      noOfCarts: "1",
      // minSlotTime: "0.5",
      sports: [],
      weekDay: [
        {
          weekDays: "",
          data: [
            {
              startTime: "00:00",
              endTime: "00:00",
              amount: "0",
              duration1: "",
              duration2: "",
              duration3: "",
              id: 0,
            },
          ],
        },
      ],
      venueId: 0,
    },
  ];

  const handleSelectChange = (i, selectedOptions) => {
    if (
      selectedOptions.length > 1 &&
      selectedOptions[0].label === "Go-Karting"
    ) {
      selectedOptions = selectedOptions.filter((option) => option.value !== 7);
    }

    const filteredOptions = selectedOptions.filter(
      (option) => option.label === "Go-Karting"
    );

    const newFormValues = [...sportsFacility];
    newFormValues[i].sports =
      filteredOptions.length > 0 ? filteredOptions : selectedOptions;

    SetSportsFacility(newFormValues);
  };

  let addFormFields = (i) => {
    let arrayData = [...listData];
    arrayData[0].weekDay[0].startTime = state?.StartTime;
    arrayData[0].weekDay[0].endTime = state?.EndTime;
    const updatedFormValues = [...sportsFacility, listData[0]];
    SetSportsFacility(updatedFormValues);
    setExpandedIndex(updatedFormValues?.length - 1);
    setErrorMessages([]);
  };

  const removeFormFields = (i) => {
    setErrorMessages([]);
    const newFormValues = [...sportsFacility];
    newFormValues.splice(i, 1);
    if (newFormValues.length === 0) {
      newFormValues.push({
        venuesportId: null,
        surfaceType: "",
        courtname: "",
        courtCount: "",
        maxCapacity: "",
        noOfCarts: "",
        sports: [],
        weekDay: [
          {
            weekDays: "",
            data: [
              {
                startTime: "",
                endTime: "",
                amount: "",
                duration1: "",
                duration2: "",
                duration3: "",
              },
            ],
          },
        ],
        venueId: 0,
      });
    }

    SetSportsFacility(newFormValues);
  };

  const handleDeleteSelectedData = (formIndex, dayIndex) => {
    const newData = sportsFacility.map((item, index) => {
      if (index === formIndex) {
        const updatedWeekDay = item.weekDay.filter(
          (_, wdIndex) => wdIndex !== dayIndex
        );
        return {
          ...item,
          weekDay: updatedWeekDay,
        };
      }
      // setAllDaysChecked(false);

      return item;
    });

    const updatedData = newData.map((item) => {
      if (item.weekDay.length === 0) {
        return {
          ...item,
          weekDay: [
            {
              weekDays: "",
              data: [
                {
                  startTime: "",
                  endTime: "",
                  amount: "",
                  duration1: "",
                  duration2: "",
                  duration3: "",
                },
              ],
            },
          ],
        };
      }
      return item;
    });

    SetSportsFacility(updatedData);
  };

  let handleChange = (i, e) => {
    if (e.target.name === "NoOfCarts" && e.target.value === "0") {
      return;
    }
    let newFormValues = [...sportsFacility];
    newFormValues[i][e.target.name] = e.target.value;
    SetSportsFacility(newFormValues);
  };
  useEffect(() => {
    const raw = JSON.stringify({ VenueId: venueId, stage: 2 });
    CustomPostAPI(`/venue/NewEditVenueDetails`, raw).then((result) => {
      result = JSON.parse(result);
      if (result[0]?.status === "Success") {
        SetIsLoading(false);
        SetSports(result[0]?.allsport);
        SetState((prev) => ({
          ...prev,
          StartTime: result[0]?.startTime,
          EndTime: result[0]?.endTime,
        }));
        SetCategory(result[0]?.category);
        SetSurfaces(result[0]?.surfaces);
        SetSportsFacility(result[0]?.sportsFacility);
      }
    });
  }, [venueId]);
  return (
    <React.Fragment>
      {isLoading ? (
        <Loader mt="20%" />
      ) : (
        <>
          <div className="row mx-0 Montserrat fs-16 fw-600 mt-3">Pricing</div>
          <div className="row mx-0" id="AddSurface">
            <div className="accordion mt-2 px-0" id="accordionExample">
              {sportsFacility?.map((element, index) => (
                <>
                  <div class="accordion-item my-3 py-2 ">
                    <h2 class="accordion-header" id={`heading-${index}`}>
                      <div
                        className="row mx-0 p-2"
                        style={{
                          borderRadius: "4px",
                        }}
                      >
                        <div
                          className="col-12 Montserrat mb-2"
                          style={{
                            backgroundColor: "#F0F0F061 !important",
                            color: "black !important",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          {category === "Sports" ? "Court/Box" : "Event Space"}{" "}
                          {index + 1}*
                        </div>
                        <div className="col-8 col-md-8 parent">
                          <input
                            type="text"
                            className="form-control px-1"
                            name="courtname"
                            value={element?.courtname}
                            onChange={(e) => {
                              handleChange(index, e);
                            }}
                            placeholder="Name"
                            required
                          />
                          <span
                            className="form-text invalid-feedback"
                            style={{ fontSize: "14px" }}
                          >
                            * This field is Required!
                          </span>
                        </div>
                        <div className="col-2 col-md-2 d-flex align-items-center">
                          {index === 0 ? (
                            <img
                              className="img-fluid "
                              src="/images/addicon.svg"
                              alt="icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                addFormFields(index);
                              }}
                            />
                          ) : (
                            <img
                              className="img-fluid "
                              src="/images/EditVenueRemoveIcon.svg"
                              alt="icon"
                              onClick={() => removeFormFields(index)}
                            />
                          )}
                        </div>
                        <div className="col-2 d-flex align-items-center">
                          <button
                            id={`accordion-button-${index}`}
                            className={`accordion-button accordion-button-child customAccordianBtn ${
                              expandedIndex !== index ? "collapsed" : ""
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded={
                              expandedIndex === index ? "true" : "false"
                            }
                            aria-controls={`collapse${index}`}
                            style={{ float: "right" }}
                            onClick={() =>
                              setExpandedIndex(
                                expandedIndex === index ? null : index
                              )
                            }
                          ></button>
                        </div>
                      </div>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${
                        expandedIndex === index ? "show" : ""
                      }`}
                      collapse
                      show
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body pt-0">
                        {category === "Sports" && (
                          <div className="row mt-2">
                            <div className="col-12">
                              <span className="venueText">Surface Type*</span>
                            </div>
                            <div className="col-12 parent">
                              <select
                                id="selectSurface"
                                required
                                className="form-select "
                                aria-label="Select Surface Type"
                                name="surfaceType"
                                value={element?.surfaceType}
                                onChange={(e) => {
                                  handleChange(index, e);
                                }}
                              >
                                <option
                                  value="0"
                                  defaultValue={true}
                                  style={{
                                    backgroundColor: "black !important",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Select
                                </option>
                                {surfaces?.map((data, i) => (
                                  <option
                                    value={data.id}
                                    defaultValue={true}
                                    style={{
                                      backgroundColor: "black !important",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                              <span
                                className="form-text invalid-feedback"
                                style={{ fontSize: "14px" }}
                              >
                                * This field is Required!
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="row mt-3">
                          <div className="col-12">
                            <span className="venueText">
                              {category === "Sports"
                                ? "Sports"
                                : "Event Space Type"}{" "}
                              *
                            </span>
                          </div>

                          <div className="col-12 parent">
                            <Select
                              key={index}
                              value={element?.sports}
                              id={`selectSports-${index}`}
                              aria-label={`Select Sports ${index + 1}`}
                              name="sports"
                              isRequired
                              onChange={(selectedOptions) =>
                                handleSelectChange(index, selectedOptions)
                              }
                              options={sports}
                              isMulti
                            />

                            <span
                              className={`form-text text-danger sport-error-${index}`}
                            ></span>
                          </div>
                        </div>
                        {element?.sports[0]?.label != "Go-Karting" ? (
                          <div className="row mt-3 parent">
                            <div className="col-12">
                              <span className="venueText">Max Capacity*</span>
                            </div>
                            <div className="col-12">
                              <input
                                required
                                type="number"
                                inputMode="numeric"
                                name="maxCapacity"
                                value={element?.maxCapacity}
                                onChange={(e) => {
                                  handleChange(index, e);
                                }}
                                className="form-control"
                              />
                              <span className="form-text invalid-feedback">
                                * This field is Required!
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="row mt-3">
                            <div className="col-12">
                              <span className="venueText"> No Of Karts*</span>
                            </div>
                            <div className="col-12 parent">
                              <input
                                required
                                type="number"
                                inputMode="numeric"
                                name="noOfCart"
                                value={element?.noOfCart}
                                onChange={(e) => {
                                  handleChange(index, e);
                                }}
                                className="form-control"
                              />
                              <span className="form-text invalid-feedback">
                                * This field is Required!
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="row mt-3 Montserrat fw-600">
                          <div className="col-12">
                            Booking Day Availability & Rates*
                          </div>
                          {areAllWeekdaysCovered(element) && (
                            <div className="col-12 Montserrat">
                              {" "}
                              <button
                                className="btn mt-3 addPriceBtn"
                                onClick={() => {
                                  setShow(true);
                                  setselectedIndex(index);
                                }}
                              >
                                <img
                                  src="/images/AddPricingBtn.svg"
                                  className="me-1"
                                  alt=""
                                />{" "}
                                Add Pricing
                              </button>
                            </div>
                          )}
                          {errorMessages[index] && (
                            <div
                              className="error-message"
                              style={{
                                color: "red",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {errorMessages[index]}
                            </div>
                          )}
                          <div className="row mt-3 mx-0 px-0">
                            {element?.weekDay?.map((day, dayIndex) => {
                              const isGoKarting =
                                element?.sports[0]?.label === "Go-Karting";

                              if (isGoKarting) {
                                return (
                                  day?.weekDays !== "" &&
                                  day?.data?.length > 0 && (
                                    <div key={dayIndex} className="col-md-6">
                                      <div
                                        className="p-3 mb-3"
                                        style={{
                                          backgroundColor: "white",
                                          borderRadius: "4px",
                                          border: " 0.5px solid #C4C4C4",
                                        }}
                                      >
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div
                                            className="fw-600"
                                            style={{ fontSize: "16px" }}
                                          >
                                            {day?.weekDays}
                                          </div>
                                          <div>
                                            <img
                                              className="cursor-pointer ms-2"
                                              src="/images/deleteicon.svg"
                                              title="Delete Slot"
                                              alt="icon"
                                              onClick={() =>
                                                handleDeleteSelectedData(
                                                  index,
                                                  dayIndex
                                                )
                                              }
                                              height="22px"
                                            />
                                          </div>
                                        </div>
                                        {day?.data?.map((item, idx) => (
                                          <div key={idx} className="mt-2">
                                            <div>
                                              {item.duration1 &&
                                                "5 Min: Amount: ₹" +
                                                  item.duration1}{" "}
                                              <br />
                                              {item.duration2 &&
                                                "10 Min: Amount: ₹" +
                                                  item.duration2}{" "}
                                              <br />
                                              {item.duration3 &&
                                                "15 Min: Amount: ₹" +
                                                  item.duration3}{" "}
                                              <br />
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )
                                );
                              } else {
                                return (
                                  day?.weekDays !== "" &&
                                  day?.data?.length > 0 &&
                                  day?.data[0]?.startTime !== "" &&
                                  day?.data[0]?.amount !== "" &&
                                  day?.data[0]?.amount !== false &&
                                  day?.data[0]?.amount !== "0" && (
                                    <div key={dayIndex} className="col-md-6">
                                      <div
                                        className="p-3 mb-3"
                                        style={{
                                          backgroundColor: "white",
                                          borderRadius: "4px",
                                          border: " 0.5px solid #C4C4C4",
                                        }}
                                      >
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div
                                            className="fw-600"
                                            style={{ fontSize: "16px" }}
                                          >
                                            {day.weekDays}
                                          </div>
                                          <div>
                                            <img
                                              className="cursor-pointer ms-2"
                                              src="/images/deleteicon.svg"
                                              title="Delete Slot"
                                              alt="icon"
                                              onClick={() =>
                                                handleDeleteSelectedData(
                                                  index,
                                                  dayIndex
                                                )
                                              }
                                              height="22px"
                                            />
                                          </div>
                                        </div>
                                        {day?.data?.map((item, idx) => (
                                          <div
                                            key={idx}
                                            className="mt-2 weekdayPriceText Montserrat"
                                          >
                                            <div>
                                              Time:{" "}
                                              {convertTo12HourFormat(
                                                item.startTime
                                              )}{" "}
                                              to{" "}
                                              {convertTo12HourFormat(
                                                item.endTime
                                              )}{" "}
                                              <br />
                                              Amount: ₹{item.amount}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )
                                );
                              }
                            })}
                          </div>
                          {element?.sports[0]?.label === "Auditorium" && (
                            <>
                              <div className="row mt-3 parent">
                                <div className="col-12">
                                  <span className="venueText">
                                    Rehearsal Price
                                  </span>
                                  &nbsp;
                                  <small>
                                    (Enter the price if you provide the
                                    auditorium for rehearsals from Monday to
                                    Thursday)
                                  </small>
                                </div>
                                <div className="col-12 mt-2">
                                  <input
                                    type="number"
                                    inputMode="numeric"
                                    name="rehearsalprice"
                                    value={element?.rehearsalprice}
                                    onChange={(e) => {
                                      handleChange(index, e);
                                    }}
                                    className="form-control"
                                    placeholder="Per hour price(optional)"
                                  />
                                  <span className="form-text invalid-feedback">
                                    * This field is Required!
                                  </span>
                                </div>
                              </div>
                              <div className="row mt-3 parent">
                                <div className="col-12">
                                  <span className="venueText">
                                    Extra Hour Charge
                                  </span>{" "}
                                  <small>
                                    (Enter the price if you charge extra amount
                                    after booking on hourly basis)
                                  </small>
                                </div>
                                <div className="col-12">
                                  <input
                                    type="number"
                                    inputMode="numeric"
                                    name="extrahourprice"
                                    value={element?.extrahourprice}
                                    onChange={(e) => {
                                      handleChange(index, e);
                                    }}
                                    className="form-control"
                                    placeholder="Extra per hour charge(optional)"
                                  />
                                  <span className="form-text invalid-feedback">
                                    * This field is Required!
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <button
              className="EditVenueSaveBtn"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Save
            </button>
          </div>
        </>
      )}

      {show && (
        <Popup
          show={show}
          onHide={() => {
            setShow(false);
            setselectedIndex("");
          }}
          venueflag={1}
        >
          <WeekDays
            WeekDayData={sportsFacility[selectedIndex]?.weekDay}
            data={sportsFacility[selectedIndex]?.sports}
            state={state}
            dispatch={SetSportsFacility}
            index={selectedIndex}
            setShow={setShow}
            ShowEnterData={sportsFacility[selectedIndex]?.weekDay}
            setselectedIndex={setselectedIndex}
            setDaysToShow={setDaysToShow}
            daysToShow={daysToShow}
            days={days}
            setDays={setDays}
          />
        </Popup>
      )}
    </React.Fragment>
  );
}
