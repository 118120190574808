import {
  React,
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { Card, Modal, Badge, Dropdown } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Select from "react-select";
// import ReactStars from "react-rating-stars-component";
import Heart from "react-heart";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CustomGetAPI,
  GetAPI,
  PostAPI,
  PutAPI,
  CustomPostAPI,
} from "../Utils/APIConnector";
import {
  capitalizeWord,
  formatDateToInput,
  venueSportsData,
} from "../Utils/common";
import SavedVenues from "./savedVenues";
import _ from "lodash";
import Loader from "../Utils/Loader";
import VerifyPhoneNumber from "../VerifyPhoneNumber";
import { validateImageSize } from "../Utils/common";
import HelmetMetaTag from "../Utils/HelmetMetaTag/HelmetMetaTag";
import LazyLoadingImg from "../LazyLoading/LazyLoadingImg";
import InfiniteScroll from "react-infinite-scroll-component";
import LocationSearch from "../SearchModal/LocationSearch";

const sportsButtonData = [
  { image: null, sportsBtnText: "All sports" },
  { image: "/images/sport-box-cricket.svg", sportsBtnText: "Cricket" },
  { image: "/images/sport-football.svg", sportsBtnText: "Football" },
  { image: "/images/sport-box-cricket.svg", sportsBtnText: "Box-cricket" },
  { image: "/images/sport-pickleball.svg", sportsBtnText: "Pickleball" },
  { image: "/images/sport-gokarting.svg", sportsBtnText: "Go karting" },
];

const TimingsButtonData = [
  {
    value: "1",
    TimimgImage: "/images/before-5am.svg",
    TimingBtnText: "Before 5am",
  },
  {
    value: "2",
    TimimgImage: "/images/5am-12pm.svg",
    TimingBtnText: "5am to 12pm",
  },
  {
    value: "3",
    TimimgImage: "/images/12pm-6pm.svg",
    TimingBtnText: "12pm to 6pm",
  },
  {
    value: "4",
    TimimgImage: "/images/after-6pm.svg",
    TimingBtnText: "After 6pm",
  },
];

export default function NewVenueListing() {
  const params = useParams();
  const userID = localStorage.getItem("userid");

  const cityName =
    params?.cityName?.split("-").length > 1
      ? params?.cityName?.split("-")[params?.cityName?.split("-").length - 1]
      : params?.cityName;

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const CreateOwnMatchDate = location?.state?.Date;
  const selectedDate = CreateOwnMatchDate
    ? CreateOwnMatchDate
    : new Date().toISOString().split("T")[0];

  const [sportId, setSportId] = useState();
  const [areaData, setAreaData] = useState();
  const [selecedArea, setSelecedArea] = useState();
  const [sportsOptions, setSportsOptions] = useState();
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset >= 60) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleAreaChange = (area) => {
    if (selecedArea.toLocaleLowerCase() === area.toLocaleLowerCase()) {
      //setSelecedArea(null);
      area = "";
      setFilterObjects((prevState) => ({
        ...prevState,
        area: "",
      }));
    } else {
      setFilterObjects((prevState) => ({
        ...prevState,
        area: area.toLocaleLowerCase(),
      }));
    }
    filterVenues(null, null, null, null, area.toLocaleLowerCase());
  };
  // const ratingChanged = (newRating) => {
  //   console.log(newRating);
  // };

  useEffect(() => {
    CustomGetAPI(
      `/venue/uniqueaddresses/${cityName}?category=${pathname?.split("/")[3]}`
    ).then((result) => {
      result = JSON.parse(result);
      if (result?.status === "success") {
        setAreaData(result?.data);
      }
    });
  }, []);

  const [active, setActive] = useState(false);
  // const [heartState,setheartState] = useState([])
  const [Filter, setFilter] = useState(false);
  const [venuesData, setVenuesData] = useState([]);
  const [SportsData, setSportsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  // const [cityValue, setCityValue] = useState('')

  const [isLoading, setIsLoading] = useState(true);

  const initialFilterObjects = JSON.parse(
    localStorage.getItem("filterObjects")
  ) || {
    name: "",
    sport: "0",
    timings: [],
    amenities: "0",
    date: selectedDate,
    sortby: "0",
    area: "",
  };
  // const [sportsIds, setSportsIds] = useState([])
  const [filterObjects, setFilterObjects] = useState(initialFilterObjects);
  useEffect(() => {
    if (new Date(filterObjects?.date) < new Date()) {
      setFilterObjects((prev) => ({
        ...prev,
        date: formatDateToInput(new Date()),
      }));
    }
  }, []);
  const [layout, setLayout] = useState("single");
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectTimings, setSelectTimings] = useState([]);
  const [showSearchList, setShowsearchList] = useState(false);
  const [HasMore, setHasMore] = useState(false);
  const [PageNumber, setPageNumber] = useState(1);
  const [selectedOption, setSelectedOption] = useState("Filters");
  const [activeSport, setActiveSport] = useState(filterObjects?.sport || "0");
  const [activeTime, setActiveTime] = useState(
    filterObjects?.timings?.value || "0"
  );
  const [selectedSort, setSelectedSort] = useState(filterObjects.sortby || "0");
  const userLocation = window.localStorage.getItem("userLocation");
  const [selectedCity, setSelectedCity] = useState(userLocation);
  const [isSearch, setIsSearch] = useState(false);
  const [cityData, setCityData] = useState();
  const SearchRef = useRef(null);
  const FilterRef = useRef(null);
  const redirectURL = window.localStorage.getItem("RedirectURL");

  const options = [
    { value: "1", label: "Early Morning(Before 5am)" },
    { value: "2", label: "Morning(05:00am-12:00pm)" },
    { value: "3", label: "Afternoon(12:00pm-6:00pm)" },
    { value: "4", label: "Evening(After 6pm)" },
  ];

  const handleSelectTimings = (selectedTime) => {
    setActiveTime(selectedTime);
    const selectedData = TimingsButtonData?.find(
      (time) => time?.value === selectedTime
    );
    if (selectedData) {
      setFilterObjects((prevState) => ({
        ...prevState,
        timings: {
          value: selectedData?.value,
          TimingBtnText: selectedData?.TimingBtnText,
        },
      }));
    }
  };

  const handleVenueListing = (name) => {
    window.localStorage.setItem(
      "venueRedirectURL",
      "/venue-search" +
        "/" +
        params?.cityName.toLocaleLowerCase() +
        `/${pathname?.split("/")[3]}/${params.sportName.toLocaleLowerCase()}`
    );
    window.location.href =
      `/venue-search/${params?.cityName.toLocaleLowerCase()}/` +
      name.toLocaleLowerCase().trim().split(" ").join("-");
  };

  const goToVenueSearch = () => {
    // window.location.href = "/home";
    //let venueURL = window.localStorage.getItem("venueRedirectURL") || "/home";
    navigate("/home");
  };

  const goToVenueList = (id, name) => {
    setSearchText(name);
    setShowsearchList(false);
    window.localStorage.setItem(
      "venueUrl",
      `/venue-search/${name.toLocaleLowerCase()}/${pathname?.split("/")[3]}/all`
    );
    window.location.href = `/venue-search/${name.toLocaleLowerCase()}/${
      pathname?.split("/")[3]
    }/all`;
  };
  const toggleFilters = (e) => {
    e.stopPropagation();
    setFilter(!Filter);
    if (!Filter) {
      document.body.classList.add("no-scroll");
      document.getElementsByClassName("filter-bg")[0].classList.add("blur-bg");
    } else {
      document.body.classList.remove("no-scroll");
      document
        .getElementsByClassName("filter-bg")[0]
        .classList.remove("blur-bg");
    }
  };

  const [sportName, setsportName] = useState();
  const handleChange = (e) => {
    if (e.target.name === "sport") {
      setsportName(e.target.label);
    }

    let val = { ...filterObjects };
    val[e.target.name] = e.target.value;
    setFilterObjects(val);

    // if (e.target.name === "date") {
    //   filterVenues(e.target.value);
    // }
  };

  const handleNameSearch = (e) => {
    delayedNameSearch(e.target.value);
  };

  const toggleLayout = () => {
    setLayout((prevState) => (prevState === "single" ? "multiple" : "single"));
  };

  async function resetFilter() {
    setIsLoading(true);
    setFilter(false);
    document.body.classList.remove("no-scroll");
    document.getElementsByClassName("filter-bg")[0].classList.remove("blur-bg");
    setFilterObjects({
      name: "",
      sport: 0,
      date: selectedDate,
      timings: "",
      sortby: "0",
      area: "",
    });
    localStorage.removeItem("filterObjects");
    // setFilterData(venuesData);
    const currentPath = window.location.pathname;
    const parts = currentPath.split("/");
    const newLocation = parts.slice(0, parts.length - 1).join("/") + `/all`;

    window.location.href = newLocation;
    const raw = JSON.stringify({
      UserId: userID ? userID : 0,
      cityname: cityName,
      Name: "",
      Sport: 0,
      Date: selectedDate,
      TimeId: "",
      Sortby: "0",
    });
  }

  async function filterVenues(date, sortby, sport, sportname, area) {
    if (date) {
      filterObjects.date = date;
    }
    if (sortby) {
      filterObjects.sortby = sortby;
    }
    if (sport) {
      filterObjects.sport = sport;
    }
    if (sportname === `All-${capitalizeWord(pathname?.split("/")[3])}`) {
      sportname = "all";
    }
    if (area === "") {
      filterObjects["area"] = "";
    }
    if (area === null || area === undefined) {
      area = selecedArea.toLocaleLowerCase();
    }
    setIsLoading(true);
    setFilter(false);
    document.body.classList.remove("no-scroll");
    document.getElementsByClassName("filter-bg")[0].classList.remove("blur-bg");
    localStorage.setItem("filterObjects", JSON.stringify(filterObjects));

    const currentPath = window.location.pathname;
    const parts = currentPath.split("/");
    const newLocation =
      parts
        .slice(0, -1)
        .join("/")
        .replace(
          /\/[a-z-]+\/[a-z-]+/,
          `/${area ? `${area?.split(" ").join("-")}-` : ""}${cityName}`
        ) +
      `/${
        sportname
          ? sportname.toLowerCase()
          : sportName
          ? sportName.toLowerCase()
          : params.sportName.toLowerCase()
      }`;

    window.location.href = `/venue-search${newLocation}`;
  }

  useLayoutEffect(() => {
    CustomGetAPI(
      `/Venue/GetCitiesAreaStatus/${
        params?.cityName?.split("-").length > 1
          ? params?.cityName
              ?.split("-")
              .slice(0, -1)
              .join(" ")
              .toLocaleLowerCase()
          : null
      }/${
        params?.cityName?.split("-").length > 1
          ? params?.cityName?.split("-")[
              params?.cityName?.split("-").length - 1
            ]
          : params?.cityName
      }`
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (!result?.isCityArea) {
          navigate("/not-found");
        } else {
          setSearchText(capitalizeWord(params?.cityName, "-", ", "));
          window.localStorage.setItem(
            "userLocation",
            params?.cityName?.toLowerCase()
          );
        }
      }
    });
  }, []);

  useEffect(() => {
    if (params?.cityName?.split("-").length > 1) {
      setFilterObjects((prevState) => ({
        ...prevState,
        area: params?.cityName?.split("-").slice(0, -1).join("-"),
      }));
      const filterobject = filterObjects;
      filterobject["area"] = params?.cityName
        ?.split("-")
        .slice(0, -1)
        .join("-");
      localStorage.setItem("filterObjects", JSON.stringify(filterobject));
      setSelecedArea(
        params?.cityName?.split("-").slice(0, -1).join(" ").toLocaleLowerCase()
      );
    } else {
      setFilterObjects((prevState) => ({
        ...prevState,
        area: "",
      }));
      const filterobject = filterObjects;
      filterobject["area"] = "";
      localStorage.setItem("filterObjects", JSON.stringify(filterobject));
      setSelecedArea();
    }
  }, [params?.cityName]);

  useEffect(() => {
    function handleClickOutside(ref) {
      return function (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowsearchList(false);
          setFilter(false);
          document.body.classList.remove("no-scroll");
          document
            .getElementsByClassName("filter-bg")[0]
            .classList.remove("blur-bg");
        }
      };
    }
    const handleSearchClick = handleClickOutside(SearchRef);
    const handleFilterClick = handleClickOutside(FilterRef);

    document.addEventListener("mousedown", handleSearchClick);
    document.addEventListener("mousedown", handleFilterClick);

    return () => {
      document.removeEventListener("mousedown", handleSearchClick);
      document.removeEventListener("mousedown", handleFilterClick);
    };
  }, [SearchRef, FilterRef]);

  const delayedSearch = useCallback(
    _.debounce(async (val) => {
      if (val !== "") {
        try {
          // setLoader(true)
          await CustomGetAPI("/Venue/GetCitiesByArea/" + val).then((result) => {
            if (result === undefined || result === "") {
              alert("Something went wrong");
            } else {
              result = JSON.parse(result);
              setShowsearchList(true);
              setSearchData(result?.cities);
            }
          });
        } catch (error) {
          // console.log(error);
        } finally {
          // setLoader(false)
        }
      } else {
        setShowsearchList(false);
      }
    }, 500),
    []
  );
  const delayedNameSearch = useCallback(
    _.debounce(async (val) => {
      setPageNumber(1);
      setVenuesData([]);
      if (val !== "") {
        try {
          getVenue(sportId, val);
        } catch (error) {
          // console.log(error);
        } finally {
          // setIsLoading(false);
        }
      } else {
        getVenue(sportId, "");
      }
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    delayedSearch(value);
    setSearchText(value);
  };
  const handleSortClick = (optionValue) => {
    setSelectedSort(optionValue);
    setFilterObjects((prev) => ({
      ...prev,
      sortby: optionValue,
    }));
  };
  const handleSportsClick = (value, sportName) => {
    setActiveSport(value);
    setFilterObjects((prev) => ({
      ...prev,
      sport: value,
    }));
    setsportName(sportName);
  };
  useEffect(() => {
    setPageNumber(1);
    setVenuesData([]);
    CustomGetAPI(`/venue/GetSportID/${params.sportName}`).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        setSportId(result);
        setFilterObjects((prevState) => ({
          ...prevState,
          sport: result,
        }));
        setIsLoading(true);
        getVenue(result);
      }
    });
  }, [cityName, params.sportName]);

  const getVenue = async (id, val = filterObjects?.name) => {
    // const timingIds = filterObjects?.timings.map((timing) => timing.value);
    try {
      const raw = JSON.stringify({
        UserId: userID ? userID : 0,
        cityname: cityName,
        Name: val,
        Sport: id,
        Date: filterObjects?.date,
        TimeId: parseInt(filterObjects?.timings?.value),
        Sortby: filterObjects?.sortby,
        areaname: filterObjects?.area,
        PageNumber: PageNumber,
        category: pathname?.split("/")[3],
      });
      await CustomPostAPI("/Venue/NewGetAllVenues", raw).then((result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          // console.log(result);
          result = JSON.parse(result);
          // console.log(result);

          setHasMore(result?.hasNext);
          setPageNumber((prevPage) => prevPage + 1);
          setVenuesData((prevData) => [...prevData, ...result.venues]);
          // setFilterData(result.venues);
          setSportsData(result.allsport);
          if (pathname?.split("/")[4] != "all") {
            const sport = pathname?.split("/")[4];
            const sportmatch = result?.allsport?.some(
              (sportItem) =>
                sportItem?.label.toLocaleLowerCase() ===
                sport.toLocaleLowerCase()
            );
            if (!sportmatch) {
              navigate("/not-found");
            }
          }
        }
      });
    } catch (error) {
      console.error("NewGetAllVenues: ", error.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (pathname?.split("/")[4] != "all") {
      const sportid = SportsData?.find(
        (sport) =>
          sport?.label?.toLowerCase() ==
          pathname?.split("/")[4].toLocaleLowerCase()
      );
      setActiveSport(sportid?.value);
    } else {
      setActiveSport("0");
    }
  }, [SportsData]);
  const isLoggedIn = window.localStorage.getItem("UserMobileNo");
  const [showLogin, setshowLogin] = useState(false);

  const [isHovering, setIsHovering] = useState(-1);
  const [metaData, setMetaData] = useState();
  const pages = [
    {
      pagename: "all",
      title: `Cricbuddy | Book ${capitalizeWord(
        pathname?.split("/")[3]
      )} Venues in ${capitalizeWord(params?.cityName, "-", " ")} Online`,
      desc: `${
        pathname?.split("/")[3] === "sports"
          ? `Discover and Book Sports Venues in ${capitalizeWord(
              params?.cityName,
              "-",
              " "
            )} online with Cricbuddy.Book box cricket, pickleball, and more with ease. Explore nearby and top-rated venues.`
          : `Discover and Book events venues like - auditoriums, theatres, and community halls in ${capitalizeWord(
              params?.cityName,
              "-",
              " "
            )} for seminars, open mic, karaoke and more.`
      }`,
      onPageData: `${
        pathname?.split("/")[3] === "sports"
          ? `Discover and book sports venues in ${capitalizeWord(
              params?.cityName,
              "-",
              " "
            )} online with Cricbuddy. Whether you're looking to book and play sports like cricket, box-cricket, football, pickleball, go kart, and more, Cricbuddy provides hassle-free options. Find the perfect spot at nearby sports venues, stadiums, and complexes to enjoy your favorite games. Start booking your sports venues near you today.`
          : `Find the perfect venues for your event in ${capitalizeWord(
              params?.cityName,
              "-",
              " "
            )}. We offer a wide range of spaces to suit every occasion. From corporate conferences to cultural events, our platform makes it easy to book event venues near you. Book auditoriums, theaters, open mics, live streaming events, stand-up comedy shows, corporate meetings, orchestra rehearsals, and housie games in ${capitalizeWord(
              params?.cityName,
              "-",
              " "
            )} effortlessly. Enjoy easy booking, multiple options, and the best prices.`
      }`,
      onPageh1: `${
        pathname?.split("/")[3] === "sports"
          ? `Book Sports Venues in ${capitalizeWord(
              params?.cityName,
              "-",
              " "
            )}`
          : `Book Events Venues in ${capitalizeWord(
              params?.cityName,
              "-",
              " "
            )}`
      }`,
    },
    {
      pagename: "box-cricket",
      title: `Cricbuddy | Book Box Cricket Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} Online`,
      desc: `Discover and Book Box Cricket Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online with Cricbuddy.Find the Ideal Box Cricket Turf for your game hassle-free.Play Box Cricket near me.`,
      onPageData: `Cricbuddy is your ultimate platform to book box cricket venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online. Whether you're looking to book box cricket grounds near you, find the best box cricket turf, or play at the finest box cricket venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}, Cricbuddy makes it easy and hassle-free. Explore a variety of box cricket turfs and book online to secure your ideal location. Enjoy playing box cricket near you with the convenience of booking through Cricbuddy. Whether it's the best box cricket ground in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} or a box cricket turf near you, we have you covered for your next game.`,
      onPageh1: `Book Box Cricket Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}`,
    },
    {
      pagename: "pickleball",
      title: `Cricbuddy | Book Pickleball Courts in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} Online`,
      desc: `Discover and Book Pickleball Courts in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online with Cricbuddy. Find the perfect pickleball court for your game hassle-free. Play pickleball near me.`,
      onPageData: `Cricbuddy is your premier platform to book pickleball courts in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online. Whether you're searching for a pickleball court near you, wanting to play pickleball in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}, or looking to book the perfect pickleball venue, Cricbuddy makes it simple and hassle-free. Easily book pickleball courts online and find the ideal location for your next game. Whether it's a pickleball venue near you or a court in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}, we have everything you need for an enjoyable game.`,
      onPageh1: `Book Pickleball Courts in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}`,
    },
    {
      pagename: "cricket",
      title: `Cricbuddy | Book Cricket Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} Online`,
      desc: `Discover and Book Cricket Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online with Cricbuddy.Find the Ideal Cricket ground for your game hassle-free. Play cricket near me.`,
      onPageData: `Cricbuddy is your go-to platform to book cricket grounds near you in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}. Whether you're looking to book an open cricket ground near you or the best cricket venue near you, Cricbuddy makes the process simple and hassle-free. Explore and book cricket grounds online with ease and find the ideal cricket complex for your next game. Enjoy the convenience of booking a cricket ground near you and get ready to play cricket.`,
      onPageh1: `Book Cricket Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}`,
    },
    {
      pagename: "go-karting",
      title: `Cricbuddy | Book Go Karting Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} Online`,
      desc: `Discover and Book Go karting Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online with Cricbuddy. Find the perfect track for your race hassle-free. Book go karting near me.`,
      onPageData: `Discover and book go karting venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online with Cricbuddy. Whether you're searching for the best go karting track near you or wanting to book a thrilling go-karting experience in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}, Cricbuddy makes it simple and hassle-free. Find the perfect track for your race and enjoy go karting near me with ease. Explore and book go karting venues online today.`,
      onPageh1: `Book Go Karting Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}`,
    },
    {
      pagename: "bowling",
      title: `Cricbuddy | Book Bowling Alleys in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} Online `,
      desc: `Discover and Book Bowling Alleys in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online with Cricbuddy. Find the perfect bowling game zone hassle-free. Book bowling venues online near me.`,
      onPageData: `Discover and book sports venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online with Cricbuddy. Whether you're looking to book and play sports like cricket, box-cricket, football, pickleball, go kart, and more, Cricbuddy provides hassle-free options. Find the perfect spot at nearby sports venues, stadiums, and complexes to enjoy your favorite games. Start booking your sports venues near you today.`,
    },
    {
      pagename: "football",
      title: `CricBuddy | Book Football Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} Online`,
      desc: `Discover and Book Football venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online with CricBuddy. Find the ideal football turf for your game hassle-free. Play Football near me.`,
      onPageData: `CricBuddy is your ultimate platform to book football venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online. Whether you're looking to book a football turf near you, find the best football ground, or secure a football pitch for your next game, CricBuddy makes it easy and hassle-free. Discover the ideal football turf in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} and book online with ease. Enjoy playing football near you with the convenience of booking through CricBuddy. From football turfs to pitches, we have everything you need to enjoy your game at the best football venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}.`,
      onPageh1: `Book Football Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}`,
    },
    {
      pagename: "auditorium",
      title: `Book Auditorium venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} Online`,
      desc: `Book auditorium halls, theatres, and community halls in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} for events, conferences and more.Book Auditorium near me. `,
      onPageData: `Find the perfect auditorium hall for your event in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}. We offer a wide range of auditorium venues to suit every occasion. From corporate conferences to cultural events, our platform makes it easy to book auditoriums near you. Discover and book auditoriums, theaters, and community halls in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} effortlessly. Enjoy easy booking, multiple options, and the best prices.`,
      onPageh1: `Book Auditorium Venues in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}`,
    },
    {
      pagename: "pool",
      title: `Cricbuddy | Book Pool Tables in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} Online`,
      desc: `Discover and Book Pool Table in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online with Cricbuddy. Find the Ideal pool table for your game hassle-free. Play pool near me.`,
      onPageData: `Discover the perfect pool table or venue near you in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} with Cricbuddy. Our platform offers a wide range of options to suit your preferences. Easily find and book top-rated pool tables, parlors, clubs, and cafes. Enjoy a hassle-free booking process and experience the thrill of pool near you.`,
      onPageh1: `Book Pool Tables in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}`,
    },
    {
      pagename: "snooker",
      title: `Cricbuddy | Book Snooker Tables in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} Online`,
      desc: `Discover and Book Snooker in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online with Cricbuddy. Find the Ideal snooker table for your game hassle-free. Play Snooker near me.`,
      onPageData: `Discover the perfect snooker table or venue in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} with Cricbuddy. Easily find and book top-rated snooker tables, parlors, cafes, and halls. Whether you're looking for a casual game with friends or a competitive match, we've got you covered. Enjoy a hassle-free booking process and experience the thrill of snooker near you.`,
      onPageh1: `Book Snooker in ${capitalizeWord(params?.cityName, "-", " ")}`,
    },
    {
      pagename: "badminton",
      title: `Cricbuddy | Book Badminton courts in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} Online`,
      desc: `Discover and Book Badminton courts in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} online with Cricbuddy. Find the Ideal Badminton court for your game hassle-free. Play Badminton near me.`,
      onPageData: `Discover the perfect badminton court in ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )} with Cricbuddy. Our platform offers a wide range of options to suit your preferences. Easily find and book top-rated badminton indoor and outdoor courts. Enjoy a hassle-free booking process and experience the thrill of badminton courts near you.`,
      onPageh1: `Book Badminton Courts in  ${capitalizeWord(
        params?.cityName,
        "-",
        " "
      )}`,
    },
  ];

  useLayoutEffect(() => {
    const page = pages.find(
      (page) => page.pagename === params.sportName.toLocaleLowerCase()
    );
    setMetaData(page);
  }, [params.sportName]);

  useLayoutEffect(() => {
    setSportsOptions([
      { value: 0, label: `All-${capitalizeWord(pathname?.split("/")[3])}` },
      ...SportsData?.map((item) => ({
        value: item.value,
        label: item.label,
      })),
    ]);
  }, [SportsData]);

  const selectedValue = sportsOptions?.find(
    (option) => option?.value?.toString() === filterObjects?.sport
  );

  useEffect(() => {
    if (params?.cityName?.split("-").length > 1) {
      setSelecedArea(
        params?.cityName?.split("-").slice(0, -1).join(" ").toLocaleLowerCase()
      );
    } else {
      setSelecedArea(filterObjects?.area.toLocaleLowerCase());
    }
  }, [filterObjects]);
  const windowWidth = window.innerWidth;

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      const scrollContainer = document.querySelector(".scrollable-container");
      let isDown = false;
      let startX;
      let scrollLeft;

      scrollContainer.addEventListener("mousedown", (e) => {
        isDown = true;
        scrollContainer.classList.add("active");
        startX = e.pageX - scrollContainer.offsetLeft;
        scrollLeft = scrollContainer.scrollLeft;
      });

      scrollContainer.addEventListener("mouseleave", () => {
        isDown = false;
        scrollContainer.classList.remove("active");
      });

      scrollContainer.addEventListener("mouseup", () => {
        isDown = false;
        scrollContainer.classList.remove("active");
      });

      scrollContainer.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - scrollContainer.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        scrollContainer.scrollLeft = scrollLeft - walk;
      });
    });
  }, []);

  useEffect(() => {
    CustomGetAPI("/venue/GetAllCity").then((result) => {
      result = JSON.parse(result);
      if (result?.status === "success") {
        setCityData(result?.cities);
      }
    });
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F5F5F5",
      fontSize: "14px",
      border: "none",
    }),
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value.toLowerCase();
    setSelectedCity(selectedCity);
    localStorage.setItem("userLocation", selectedCity);
    window.location.href = `/venue-search/${selectedCity}/${
      pathname?.split("/")[3]
    }/all`;
  };
  return (
    <>
      <HelmetMetaTag
        titleName={metaData?.title}
        ogdesc={metaData?.desc}
        desc={metaData?.desc}
        canonical={window.location.href}
        ogtitle={metaData?.title}
        ogtype="website"
        ogurl={window.location.href}
      />
      <div className="px-md-5 px-2 filter-bg ">
        <div className="row mx-0 px-2 align-items-center">
          <div className="col-1 ps-0">
            <img
              src="/images/backArrow.svg"
              onClick={goToVenueSearch}
              style={{ cursor: "pointer" }}
            />
          </div>

          {windowWidth >= 900 ? (
            <>
              <div className="col-11 d-flex justify-content-end pe-0">
                <div className="col-md-2 col-7 d-flex align-items-center justify-content-center">
                  <LocationSearch />
                  {/* <input
                    value={searchText
                      .split("-")
                      .map((part, index, array) =>
                        index === array.length - 2 ? part + "," : part
                      )
                      .join(" ")}
                    onChange={(e) => {
                      handleSearchChange(e);
                    }}
                    type="text"
                    className="form-control Montserrat venueListingTopMenu h-100 h-100"
                    style={{ position: "relative", paddingLeft: "30px" }}
                  /> */}

                  {/* <>
                    <div
                      className="results-list"
                      style={{
                        zIndex: "9",
                        position: "absolute",
                      }}
                    >
                      {searchData.map((result, id) => {
                        return (
                          <div
                            className="search-result"
                            onClick={(e) =>
                              goToVenueList(result?.id, result?.name)
                            }
                            key={id}
                          >
                            {result?.name
                              ?.split("-")
                              .map((part, index, array) =>
                                index === array.length - 2 ? part + "," : part
                              )
                              .join(" ")}
                          </div>
                        );
                      })}
                    </div>
                  </>

                  <img
                    src="/images/Location(1).svg"
                    alt="location"
                    className="locationSearch"
                  /> */}
                </div>
                <div className="col-md-2 col-5 me-2">
                  <input
                    type="text"
                    className="form-control customize-placeholder venueListingTopMenu h-100"
                    name="name"
                    placeholder="Venue Name"
                    value={filterObjects?.name}
                    style={{ paddingLeft: "10px" }}
                    onChange={(e) => {
                      handleChange(e);
                      handleNameSearch(e);
                    }}
                  />
                </div>
                <div className="col-md-2 col-5 mt-3 mt-md-0 me-2">
                  <Select
                    className="basic-single Montserrat venueListingTopMenu h-100"
                    styles={customStyles}
                    classNamePrefix="select"
                    value={selectedValue}
                    isSearchable="true"
                    name="sport"
                    options={sportsOptions}
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "sport",
                          value: selectedOption?.value,
                          label: selectedOption?.label,
                        },
                      });
                      filterVenues(
                        null,
                        null,
                        selectedOption?.value,
                        selectedOption?.label
                      );
                    }}
                  />
                  {/* <select
                id="selectSport"
                className="custom-select"
                aria-label="Select Sport"
                name="sport"
                value={filterObjects?.sport}
                onChange={(e) => {
                  handleChange(e);
                  const selectedOptionIndex = e.target.selectedIndex;
                  const selectedOptionLabel = e.target[selectedOptionIndex].text;
                  filterVenues(null, null, e.target.value, selectedOptionLabel);
                }}
              >
                <option value="0">Select Sports</option>
                {SportsData?.map((item, i) => (
                  <option key={i} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select> */}
                </div>
                <div className="col-md-2 col-5 me-2">
                  <select
                    id="sortby"
                    className="custom-select Montserrat venueListingTopMenu h-100"
                    aria-label="sortby"
                    name="sortby"
                    value={filterObjects?.sortby}
                    onChange={(e) => {
                      handleChange(e);
                      filterVenues(null, e.target.value);
                    }}
                  >
                    <option value="0"> Latest Created</option>
                    <option value="1">Price: low to high</option>
                    <option value="2"> Price: high to low</option>
                  </select>
                </div>

                <div className="col-md-1 col-5 mt-3 mt-md-0">
                  <button
                    className="filterButtons px-1 w-100 h-100"
                    style={{ background: "#3C3C40", fontSize: "14px" }}
                    onClick={() => resetFilter()}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-5 d-flex">
                <LocationSearch />
                {/* <img src="/images/red-location.svg" alt="location" /> */}
                {/* <select
                  name="SelectCity"
                  className="border-0 fw-semibold Montserrat"
                  id="SelectCity"
                  style={{
                    fontSize: "14px",
                    background: "transparent",
                    backgroundColor: "inherit",
                    color: "inherit",
                  }}
                  value={selectedCity}
                  onChange={handleCityChange}
                >
                  {cityData?.map((city, i) => (
                    <option key={i} value={city.cityName.toLowerCase()}>
                      {city.cityName}
                    </option>
                  ))}
                </select> */}
              </div>
              <div className="col-6 pe-0">
                <input
                  type="text"
                  className={`${
                    isSearch ? "show" : ""
                  } search-bg form-control customize-placeholder`}
                  placeholder="Search for Venues"
                  style={{ background: "#f3f3f3" }}
                  name="name"
                  value={filterObjects?.name}
                  onChange={(e) => {
                    handleChange(e);
                    handleNameSearch(e);
                  }}
                  onFocus={() => {
                    setIsSearch(true);
                  }}
                  onBlur={() => {
                    setIsSearch(false);
                  }}
                />
                {/* <img
    src="/images/search-icon-new.svg"
    alt="search"
    className="searchVenues"
  /> */}
              </div>

              <div className="">
                <img
                  className="filter-icon"
                  src="/images/filtervenues.svg"
                  alt=""
                  onClick={toggleFilters}
                />
              </div>

              <div className="px-3 my-3">
                <div className="row discountWrapper">
                  <div className="col-12 d-flex align-items-center px-2 py-3">
                    <img
                      src="/images/disount-img.svg"
                      alt="disountimg"
                      className="me-2"
                      style={{ height: "20px" }}
                    />
                    <label
                      className="fw-normal text-white Montserrat"
                      style={{ fontSize: "12px" }}
                    >
                      Get <span className="fw-bold">10% OFF</span> ON First
                      VENUE BOOKING
                      <br /> <small>Offer Valid on Selected Venues</small>{" "}
                      <br /> <small>(App Exclusive)</small>
                    </label>
                  </div>
                  {/* <div className="col-4">
                    <img
                      src="/images/girl-basketball.svg"
                      alt="girl-basketball"
                    />
                  </div> */}
                </div>
              </div>
            </>
          )}
        </div>

        <div className="mt-md-2 px-3">
          <h1 className="px-0 venueListingH1 Montserrat fw-bold">
            {metaData && metaData?.onPageh1}
          </h1>
          {/* {areaData && areaData?.length > 0 && (
              <div className="scrollable-container">
                {areaData?.map((area) => (
                  <button
                    className={`${selecedArea.toLocaleLowerCase() ===
                      area?.addressline2.toLocaleLowerCase()
                      ? "active"
                      : ""
                      } areaBtn  me-3 mb-0`}
                    onClick={() => {
                      handleAreaChange(area?.addressline2);
                    }}
                  >
                    {area?.addressline2}
                  </button>
                ))}
              </div>
            )} */}
          <div
            className={`scrollable-container ${
              isSticky ? "sticky-container px-md-5 px-2" : ""
            }`}
          >
            {areaData?.length > 0 &&
              areaData
                .sort((a, b) => {
                  // Prioritize the active button
                  if (
                    selecedArea?.toLowerCase() === a.addressline2?.toLowerCase()
                  )
                    return -1;
                  if (
                    selecedArea?.toLowerCase() === b.addressline2?.toLowerCase()
                  )
                    return 1;

                  // Sort alphabetically otherwise
                  return a.addressline2.localeCompare(b.addressline2);
                })
                .map((area) => (
                  <button
                    key={area.addressline2} // Ensure each button has a unique key
                    className={`${
                      selecedArea?.toLowerCase() ===
                      area.addressline2?.toLowerCase()
                        ? "active"
                        : ""
                    } areaBtn me-3 mb-0 Montserrat`}
                    onClick={() => {
                      handleAreaChange(area.addressline2);
                    }}
                  >
                    {area.addressline2}
                  </button>
                ))}
          </div>
        </div>
        <div className="mt-md-4 mt-3">
          {isLoading ? (
            <>
              <Loader mt="10%" />
            </>
          ) : (
            <>
              <InfiniteScroll
                dataLength={venuesData?.length}
                next={() => getVenue(sportId)}
                hasMore={HasMore}
                loader={
                  <img
                    className="mb-4 blur-bg"
                    height="50px"
                    width="50px"
                    src="/images/loder.svg"
                    // style={{ marginLeft: "45%" }}
                  />
                }
                className="row"
              >
                {venuesData?.length != 0 ? (
                  venuesData?.map((venue, index) => (
                    <div
                      className="col-md-4 col-12 mb-md-5 mb-4 px-4"
                      key={index}
                    >
                      <Card
                        className="cursor-pointer h-100"
                        style={{
                          borderBottom: "0px, 0px, 0.5px, 0px solid #E1E1E1",
                          borderRadius: "16px",
                          boxShadow: "0px 0px 5px 0px #0000001A",
                          // opacity: !venue.isAvailable ? 0.6 : 1,
                          position: "relative",
                          border: "0.5px solid #E1E1E1",
                        }}
                        onClick={() => handleVenueListing(venue?.name)}
                      >
                        {venue?.hasOffers && (
                          <img
                            src="/images/specialoffer.svg"
                            className="img-fluid"
                            alt="Special Offer"
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              zIndex: 1,
                              maxWidth: "100px",
                            }}
                          />
                        )}
                        <Card.Body
                          className="py-0 px-0 d-flex flex-column"
                          onMouseEnter={() =>
                            setIsHovering((prev) => {
                              return prev ? prev : index;
                            })
                          }
                          onMouseLeave={() => setIsHovering(null)}
                        >
                          <div className="venueImages position-relative mb-3">
                            <div className="row" style={{ padding: "0 12px" }}>
                              <div className="col-12 px-0">
                                <Carousel
                                  controls={false}
                                  interval={isHovering === index ? 3000 : null}
                                  //interval={3000}
                                  indicators={true}
                                >
                                  {venue?.venueImages?.map((data, i) => (
                                    <Carousel.Item
                                      className="Carousel-radius"
                                      key={i}
                                    >
                                      <LazyLoadingImg
                                        src={data}
                                        alt="image"
                                        title=""
                                        className="venuePhotos w-100"
                                      />
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                              </div>
                            </div>
                          </div>
                          <div className="cursor-pointer">
                            <div className="row mx-0 mt-1">
                              <div className="col-md-6 col-7 pe-0">
                                <div className="row">
                                  <div className="col-12">
                                    <h2
                                      className="Montserrat fw-semibold m-0"
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {venue?.name}
                                    </h2>
                                  </div>
                                  <div className="col-12">
                                    <p
                                      className="Montserrat"
                                      style={{
                                        color: "#5c5c5c",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {venue?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-5 ps-0 text-end">
                                <div className="text-end">
                                  {venue?.venueSports?.map((data, i) => (
                                    <>
                                      {i < 2 && (
                                        <div className="sportimage ms-2">
                                          <img
                                            src={`/images/${venueSportsData(
                                              data?.name
                                            )}`}
                                            className="img-fluid"
                                            alt="image"
                                            title={data?.name}
                                            style={{
                                              borderRadius: "11px",
                                              padding: "3px 3px",
                                              height: "30px",
                                              width: "30px",
                                            }}
                                          />
                                        </div>
                                      )}
                                      {i === 2 &&
                                        venue?.venueSports?.length > 2 && (
                                          <div
                                            className="mx-lg-1 mx-1 Montserrat"
                                            style={{ fontSize: "14px" }}
                                          >
                                            + {venue?.venueSports?.length - 2}{" "}
                                            more
                                          </div>
                                        )}
                                    </>
                                  ))}

                                  {venue?.venueSports?.length === 0 && (
                                    <span className="venueText">
                                      No sports available
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row align-items-center mx-0 mb-3">
                              <div className="col-6">
                                <div className="venuePriceDetails">
                                  <span className="text-black fw-bold">
                                    ₹{venue?.averagePrice}
                                  </span>
                                  <span className="">&nbsp;onwards</span>
                                </div>
                              </div>
                              {(venue?.isAcrive === 4 ||
                                venue?.isAcrive === 5 ||
                                venue?.isAcrive === 6) && (
                                <div className="col-md-6 col-6 text-end h-100">
                                  <button className="venueListingBooknow">
                                    {venue?.isAcrive === 4
                                      ? "Book Now"
                                      : venue?.isAcrive === 5
                                      ? "Book Now"
                                      : venue?.isAcrive === 6
                                      ? "Inquire Now"
                                      : null}
                                    <img
                                      src="/images/white-arrow.svg"
                                      alt="arrow"
                                      className="mx-1"
                                    />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* {!venue?.isAvailable && (
                            <Badge
                              bg="danger"
                              className="position-absolute top-0 end-0 m-2"
                            >
                              Booked
                            </Badge>
                          )} */}
                          {/* {(venue?.isAcrive === 4 || venue?.isAcrive === 5) && (
                              <Badge
                                bg="success"
                                className="position-absolute left-0 top-0 m-2"
                              >
                                Verified
                              </Badge>
                            )} */}
                        </Card.Body>
                      </Card>
                    </div>
                  ))
                ) : (
                  <div className="citiesbox text-center border-0 mb-5">
                    We couldn't find any venues available in your city right
                    now. Please try again later.
                  </div>
                )}
              </InfiniteScroll>

              <div className="row mx-0 px-md-5 px-2 py-3 venueListingBorder">
                <div className="col-12 ">
                  <p className="m-0 fw-600">
                    {metaData && metaData?.onPageData}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={`fade modal-backdrop ${
          Filter || isSearch ? "show" : "d-none"
        }`}
      ></div>
      <Modal
        show={showLogin}
        onHide={() => setshowLogin(false)}
        // backdrop="static"
      >
        {/* <Modal.Header closeButton onClick={() => setshowLogin(false)}>
                    <Modal.Title className="colorClass">Confirm Phone Number</Modal.Title>
                  </Modal.Header> */}

        <Modal.Body>
          <img
            src="/images/Closeicon.svg"
            className="closeicon"
            alt=""
            onClick={() => setshowLogin(false)}
          />
          <VerifyPhoneNumber
            phonenumber={isLoggedIn}
            setshowLogin={setshowLogin}
            isfromvenueowner={false}
          />
        </Modal.Body>

        {/* <Modal.Footer>
                    <small className="d-none bookederror text-center text-danger w-100"></small>
                    <button
                     
                      className="venueListingTop booking w-100"
                    >
                     Verify
                    </button>
                  </Modal.Footer> */}
      </Modal>
      <div
        className={`filter-popup ${Filter ? "visible" : "d-none"}`}
        ref={FilterRef}
      >
        <div className="popup-content" style={{ padding: "20px" }}>
          <div className="text-center">
            <div
              className="btn-group"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                value="Filters"
                id="btnradio1"
                autoComplete="off"
                checked={selectedOption === "Filters"}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              <label
                className="btn btn-custom d-flex align-items-center"
                htmlFor="btnradio1"
              >
                <img
                  src="/images/filter-icon.svg"
                  alt="filter"
                  className={`me-1 ${
                    selectedOption === "Filters" ? "checked-img" : ""
                  }`}
                />
                Filters
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                value="Sort"
                id="btnradio2"
                autoComplete="off"
                checked={selectedOption === "Sort"}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              <label
                className="btn btn-custom d-flex align-items-center"
                htmlFor="btnradio2"
              >
                <img
                  src="/images/sort-icon.svg"
                  alt="sort"
                  className={`me-1 ${
                    selectedOption === "Sort" ? "checked-img" : ""
                  }`}
                />
                Sort
              </label>
            </div>
          </div>
          {selectedOption === "Filters" && (
            <>
              <div
                className="accordion custom-accordion mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button Montserrat fw-semibold"
                      style={{ background: "white" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {capitalizeWord(pathname?.split("/")[3])}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className="accordion-body"
                      style={{ background: "white" }}
                    >
                      <div
                        className="row"
                        style={{ maxHeight: "200px", overflow: "scroll" }}
                      >
                        <div className="col-6 p-2 ">
                          <button
                            className={`sportsButton px-2 py-2 ${
                              activeSport === "0" ? "activeIndex" : ""
                            }`}
                            onClick={() => handleSportsClick("0", "all")}
                          >
                            All-
                            {capitalizeWord(pathname?.split("/")[3])}
                          </button>
                        </div>
                        {SportsData.map((sport, index) => {
                          const imageSrc = venueSportsData(sport.label);
                          return (
                            <div key={index} className="col-6 p-2 ">
                              <button
                                className={`sportsButton px-2 py-2 ${
                                  activeSport === sport.value
                                    ? "activeIndex"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleSportsClick(sport.value, sport.label)
                                }
                              >
                                {sport.label}
                                {imageSrc ? (
                                  <img
                                    src={`/images/${imageSrc}`}
                                    alt="sport icon"
                                    className={`pe-2 ${
                                      activeSport === sport.value
                                        ? "checked-img"
                                        : ""
                                    }`}
                                    style={{
                                      height:
                                        sport?.label === "Cricket" ||
                                        sport?.label === "Box-Cricket"
                                          ? "20px"
                                          : "20px",
                                    }}
                                  />
                                ) : null}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed Montserrat fw-semibold"
                      style={{ background: "white" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Availability
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className="accordion-body"
                      style={{ background: "white" }}
                    >
                      <div className="row">
                        <div className="col-12 mb-2">
                          <input
                            type="date"
                            className="form-control Montserrat venueListingTopMenu h-100"
                            min={new Date().toJSON().slice(0, 10)}
                            name="date"
                            value={filterObjects?.date}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>

                        {TimingsButtonData.map((time, index) => (
                          <div key={index} className={`col-6 py-2`}>
                            <button
                              className={`sportsButton px-2 w-100 ${
                                activeTime === time?.value ? "activeIndex" : ""
                              }`}
                              onClick={() => handleSelectTimings(time?.value)}
                            >
                              {time.TimingBtnText}
                              <img
                                src={time?.TimimgImage}
                                alt="image"
                                className={`me-1 ${
                                  activeTime === time?.value
                                    ? "checked-img"
                                    : ""
                                }`}
                              />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {selectedOption === "Sort" && (
            <div className="text-start d-flex flex-column py-4">
              <div
                className={`sort-option Montserrat ${
                  selectedSort === "0" ? "activeIndex" : ""
                }`}
                onClick={() => handleSortClick("0")}
              >
                Latest Created
              </div>
              <div
                className={`sort-option Montserrat ${
                  selectedSort === "1" ? "activeIndex" : ""
                }`}
                onClick={() => handleSortClick("1")}
              >
                Price: low to high
              </div>
              <div
                className={`sort-option Montserrat ${
                  selectedSort === "2" ? "activeIndex" : ""
                }`}
                onClick={() => handleSortClick("2")}
              >
                Price: high to low
              </div>
            </div>
          )}
          <div className="d-flex justify-content-evenly my-3">
            <button
              className="venueListingFilterBtn"
              style={{ background: "#3C3C3C" }}
              onClick={() => resetFilter()}
            >
              Reset Filter
            </button>
            <button
              className="venueListingFilterBtn"
              style={{ background: "#BA5457" }}
              onClick={() => filterVenues()}
            >
              Apply Filter
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
