import { useState, useEffect } from "react";
import { getNextSaturdayDate } from "./constants";
import Loader from "./Utils/Loader";
import { Button, Modal } from "react-bootstrap";
import { CustomGetAPI } from "./Utils/APIConnector";
import Swal from "sweetalert2";

export function InviteAndEarn() {
  const [showModal, setShowModal] = useState(false);
  const [encryptedID, setEncryptedID] = useState();
  const [walletBalance, setwalletBalance] = useState();
  const Userid = localStorage.getItem("userid");
  const loggedin = localStorage.getItem("loggedin");
  useEffect(() => {
    if (!loggedin) {
      window.location.href = "/home";
    }
  }, [loggedin]);
  useEffect(() => {
    encryptId();
  }, [Userid]);
  const encryptId = async () => {
    await CustomGetAPI(`/User/GetEncryptedvalue/${Userid}`).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setEncryptedID(result?.userid);
        setwalletBalance(result?.walletBalance);
      }
    });
  };
  const referralLink = `${window.location.origin}/registration?referby=${encryptedID}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    Swal.fire({
      toast: true,
      position: "top",
      icon: "success",
      title: "Referral link copied!",
      showConfirmButton: false,
      timer: 3000,
    });
  };
  const shareOnSocial = (platform) => {
    const message =
      "Hey! Want to save some cash on your next sports venue booking? I just used Cricbuddy, and it was super convenient! They're offering 10% off your first booking, and you can earn ₹50 for every friend you refer. Check it out: ";

    let url = "";
    switch (platform) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          referralLink
        )}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          referralLink
        )}&text=${encodeURIComponent(message)}`;
        break;
      case "whatsapp":
        url = `https://wa.me/?text=${encodeURIComponent(
          message + referralLink
        )}`;
        break;
      case "linkedin":
        url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          referralLink
        )}`;
        break;
      default:
        return;
    }
    window.open(url, "_blank", "width=600,height=400");
  };

  return (
    <>
      <div
        className=" px-0 "
        style={{
          backgroundImage: `url(/images/${
            window.innerWidth < 900
              ? "refferal_bg_desk.png"
              : "refferal_bg_desk.png"
          })`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="row px-2">
          <div className="col-2">
            <img
              src="/images/backArrow.svg"
              onClick={() => window.history.go(-1)}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="col-12 pt-5 pb-4 px-4">
            <div className="row bg-faf4f4 mx-0 pt-2 p-3 referralRow position-relative">
              <div className="col-12 text-center pb-2">
                <img src="./images/referralBat.png" alt="" />
              </div>
              <div className="col-12 text-center">
                <b>Get Rs50</b>
                <br />
                <p className="fw-500">For every new user you refer</p>
                <p className="fw-500 shareText">
                  Share your refer link and earn Rs50{" "}
                </p>
              </div>
              <div className="col-12 mt-3">
                {" "}
                <div className="referralLink px-3 position-relative">
                  <img
                    onClick={copyToClipboard}
                    src="./images/copy.svg"
                    alt=""
                    className="me-2"
                  />
                  <b>{referralLink}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-2">
          <div className="col-12 text-center">
            <div className="mx-3" style={{ opacity: "0.3" }}>
              Copy link or share on:
            </div>
            <div
              className="d-flex justify-content-center mt-3"
              style={{ maxHeight: "30px" }}
            >
              <img
                onClick={() => shareOnSocial("facebook")}
                className="img-fluid cursor-pointer"
                src="./images/circle-fb.svg"
                alt=""
              />
              <img
                onClick={() => shareOnSocial("twitter")}
                className="img-fluid cursor-pointer"
                src="./images/X.svg"
                alt=""
              />
              <img
                onClick={() => shareOnSocial("whatsapp")}
                className="img-fluid cursor-pointer"
                src="./images/Whatsapp.svg"
                alt=""
              />
              <img
                onClick={() => shareOnSocial("linkedin")}
                className="img-fluid cursor-pointer"
                src="./images/linkedin.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row  px-2">
          <div className="col-12 px-4 mt-4 ">
            <div className="referBalance p-2 text-center">
              <small> Available Balance</small> <p>Rs{walletBalance}</p>
            </div>
          </div>
        </div>
        <div className="row  px-2">
          <div className="col-12 px-4 mt-4 ">
            <div className="referBalance p-3 text-start">
              <p
                className="fw-400"
                style={{ color: "#000000", fontSize: "18px" }}
              >
                {" "}
                Terms and Conditions for Referrals:
              </p>
              <p
                className="pt-2 fw-400"
                style={{ color: "#000000", fontSize: "18px" }}
              >
                1. You will receive ₹50 in your account after the person you
                refer successfully signs up on our platform.
              </p>
              <p
                className="fw-400"
                style={{ color: "#000000", fontSize: "18px" }}
              >
                2. A maximum of ₹50 from your referral balance can be used per
                booking.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
