import React, { useEffect, useState } from "react";
import Select from "react-select";
import Loader from "../Utils/Loader";
import { CustomPostAPI } from "../Utils/APIConnector";
import TimePicker from "react-bootstrap-time-picker";
import {
  CheckValidation,
  compressImage,
  convertDecimalToTime,
  minToHour,
  validateEmail,
} from "../Utils/common";

export default function EditVenuePage1({
  venueId,
  setIsMainPage,
  setSelectedPage,
}) {
  const [isLoading, SetIsLoading] = useState(true);
  const [venueDetials, setVenueDetails] = useState();
  const [deletedimages, setdeletedimages] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [slotDuration, setSlotDuration] = useState();

  function removeMultipleImages(indexToRemove, id) {
    if (id != 0) {
      let ob = new Object();
      ob.Id = id;
      setdeletedimages((prev) => [...prev, ob]);
    }
    const updatedImages = [...venueDetials?.venueImages];
    URL.revokeObjectURL(updatedImages[indexToRemove]);
    updatedImages.splice(indexToRemove, 1);
    setVenueDetails((prevDetails) => ({
      ...prevDetails,
      venueImages: updatedImages,
    }));
  }

  useEffect(() => {
    const raw = JSON.stringify({ VenueId: venueId, stage: 1 });
    CustomPostAPI(`/venue/NewEditVenueDetails`, raw).then((result) => {
      result = JSON.parse(result);
      if (result[0]?.status === "Success") {
        SetIsLoading(false);
        setVenueDetails(result[0]);
        setSlotDuration(minToHour(result[0]?.minSlotTime));
      }
    });
  }, [venueId]);

  useEffect(async () => {
    if (
      venueDetials?.address?.stateId != "0" ||
      venueDetials?.address?.zipcode.length === 6
    ) {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            venueDetials?.address?.zipcode
          )}&key=AIzaSyBeR6pBza9LE_fFj5akK8dbfWxiST2fk38`
        );

        if (response.ok) {
          const data = await response.json();
          if (data?.results?.length === 0) {
            // dispatch({
            //   type: "SET_ERROR",
            //   payload: {
            //     name: "ZipCode",
            //     message: "*Invalid Zip Code",
            //   },
            // });
          }
          let options = [];
          if (data?.results[0]?.postcode_localities != undefined) {
            options = data?.results[0]?.postcode_localities?.map(
              (locality) => ({
                value: locality,
                label: locality,
              })
            );
          }
          setAreaOptions(options);
        }
      } catch (ex) {}
    }
  }, [venueDetials?.address?.stateId, venueDetials?.address?.zipcode]);

  const handleZipCode = async (e) => {
    handleChange(e, true);
    setAreaOptions([]);
    setVenueDetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        ["addressline2"]: "",
      },
    }));
    handleChange(e);
    if (e.target.value.length === 6) {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            e.target.value
          )}&key=AIzaSyBeR6pBza9LE_fFj5akK8dbfWxiST2fk38`
        );

        if (response.ok) {
          const data = await response.json();
          if (data?.results?.length === 0) {
            const errorDiv =
              document.getElementsByClassName("zipinvaliderrordiv");
            errorDiv[0].classList.remove("d-none");
          } else {
            const errorDiv =
              document.getElementsByClassName("zipinvaliderrordiv");
            errorDiv[0].classList.add("d-none");
          }
          let options = [];
          if (data?.results[0]?.postcode_localities != undefined) {
            options = data?.results[0]?.postcode_localities?.map(
              (locality) => ({
                value: locality,
                label: locality,
              })
            );
            const addressPart = data?.results[0]?.formatted_address?.split(",");
            const state = addressPart[addressPart.length - 2];
            const city = addressPart[addressPart.length - 3];

            setVenueDetails((prevDetails) => ({
              ...prevDetails,
              address: {
                ...prevDetails.address,
                ["stateId"]: state?.split(" ")[1],
              },
            }));
            setVenueDetails((prevDetails) => ({
              ...prevDetails,
              address: {
                ...prevDetails.address,
                ["cityId"]: city,
              },
            }));
          }

          setAreaOptions(options);
        }
      } catch (error) {}
    } else {
      // validateZipCode(e.target.value);
    }
  };

  const ValidateEmail = (e) => {
    const { value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value) && value) {
      const errorDiv = document.getElementsByClassName("emailerrordiv");
      errorDiv[0].classList.remove("d-none");
      return false;
    } else {
      const errorDiv = document.getElementsByClassName("emailerrordiv");
      errorDiv[0].classList.add("d-none");
      return true;
    }
  };

  const handleChange = (e, address) => {
    const { name, value } = e.target;
    if (
      (name === "cancellationTime" ||
        name === "rescheduleHours" ||
        name === "rescheduleCount") &&
      value < 0
    ) {
      return;
    }
    if (name === "customtime" && value === "false") {
      setVenueDetails((prevDetails) => ({
        ...prevDetails,
        ["endTime"]: "23:59",
      }));
      setVenueDetails((prevDetails) => ({
        ...prevDetails,
        ["startTime"]: "00:00",
      }));
    }
    if (name === "phone" && value.length > 10) {
      return;
    }
    if (address) {
      setVenueDetails((prevDetails) => ({
        ...prevDetails,
        address: {
          ...prevDetails.address,
          [name]: value,
        },
      }));
    } else {
      setVenueDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  function convertSecondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    const timeString = `${formattedHours}:${formattedMinutes}`;

    return timeString;
  }

  const handleTimeChange = (time, name) => {
    let newTimes = convertSecondsToTime(time);
    setVenueDetails((prevDetails) => ({
      ...prevDetails,
      [name]: newTimes,
    }));
  };

  const handleSubmit = (e) => {
    const isFormValid = CheckValidation({ formID: "VenueDetails" });
    if (!isFormValid) {
      window.scrollTo(0, 0);
      return;
    }
    if (venueDetials?.venueImages?.length === 0) {
      var errorDiv = document.getElementsByClassName("venueimageserror");
      errorDiv[0].classList.remove("d-none");
      window.scrollTo(0, 0);
      return;
    } else {
      var errorDiv = document.getElementsByClassName("venueimageserror");
      errorDiv[0].classList.add("d-none");
    }
    if (venueDetials?.phone?.length != 10) {
      var errorDiv = document.getElementsByClassName("phoneerrordiv");
      errorDiv[0].classList.remove("d-none");
      window.scrollTo(0, 0);
      return;
    } else {
      var errorDiv = document.getElementsByClassName("phoneerrordiv");
      errorDiv[0].classList.add("d-none");
    }

    const validemail = validateEmail(venueDetials?.email);
    if (!validemail) {
      window.scrollTo(0, 0);
      return;
    }
    if (!selectedArea) {
      window.scrollTo(0, 0);
      setAreaRequired(true);
      return;
    } else {
      setAreaRequired(false);
    }
    SetIsLoading(true);
    var raw = JSON.stringify({
      VenueName: venueDetials?.venueName,
      Addressline1: venueDetials?.address?.addressline1,
      Addressline2: venueDetials?.address?.addressline2,
      Addressline3: venueDetials?.address?.addressline3,
      zipcode: venueDetials?.address?.zipcode,
      description: venueDetials?.description,
      cityid: venueDetials?.address?.cityId,
      stateid: venueDetials?.address?.stateId,
      CancellationTime: venueDetials?.cancellationTime,
      Phone: venueDetials?.phone,
      Email: venueDetials?.email,
      StartTime: venueDetials?.startTime,
      EndTime: venueDetials?.endTime,
      venueimage: venueDetials?.venueImages,
      stage: 1,
      VenueId: venueDetials?.id,
      deletedimages: deletedimages,
      RescheduleCount: venueDetials?.rescheduleCount,
      RescheduleHours: venueDetials?.rescheduleHours,
      category: venueDetials?.category,
      MinSlotTime: Math.round(slotDuration * 60),
    });

    CustomPostAPI("/Venue/Editvenue", raw).then((result) => {
      if (result === undefined || result === "") {
        // alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        window.location.reload();
        // setSelectedPage();
        // setIsMainPage(true);

        // setMultipleImages(result?.venueimg);
        //
      }
    });
    SetIsLoading(false);
  };
  const [selectedArea, setSelectedArea] = useState();
  const [areaRequired, setAreaRequired] = useState(false);
  useEffect(() => {
    const selected = areaOptions?.find(
      (area) => area.value === venueDetials?.address?.addressline2
    );
    if (selected) {
      setSelectedArea(selected);
    } else {
      setSelectedArea("");
    }
  }, [venueDetials?.address?.addressline2, areaOptions]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  async function handleMultipleImagesUpload(e) {
    const files = e.target.files;
    let list = [];
    let errors = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = file.name.split(".").pop().toLowerCase();
      if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
        errors.push(
          `Invalid file type for ${file.name}. Only JPG, JPEG, and PNG files are allowed.`
        );
        continue;
      }
      let compressedFile = await compressImage(file);

      let base64 = await toBase64(compressedFile);
      let ob = {
        image: base64,
        id: 0,
      };
      list.push(ob);
    }
    // const venueImageErrorElement = document.querySelector(".venueimageerror");
    // if (errors.length > 0) {
    //   venueImageErrorElement.textContent =
    //     "*Only png,jpg,jpeg extension are supported!";
    // } else {
    //   venueImageErrorElement.textContent = "";
    // }
    setVenueDetails((prevState) => ({
      ...prevState,
      venueImages: [...prevState.venueImages, ...list],
    }));
  }
  return (
    <React.Fragment>
      {" "}
      <>
        {isLoading ? (
          <Loader mt="30%" />
        ) : (
          <div id="VenueDetails" className="mt-3">
            <div className="text-start fw-semibold Montserrat pb-2 fs-16">
              Venue Information
            </div>
            <div className="">
              <div className="row Editblock mx-0 Montserrat fw-600 fs-14">
                <div className="col-12 mt-3">
                  Is this venue for Sports or Events?*
                </div>
                <div className="col-12">
                  {" "}
                  <div className="row">
                    <div className="col-md-4 col-4 my-1">
                      <input
                        type="radio"
                        name="category"
                        value="Sports"
                        id="Sports"
                        className="form-check-input me-1 "
                        onChange={(e) => handleChange(e)}
                        checked={venueDetials?.category === "Sports"}
                        disabled
                      />
                      <label htmlFor="Sports" className="CategoryText">
                        Sports
                      </label>
                    </div>
                    <div className="col-md-4 col-6 my-1">
                      <input
                        type="radio"
                        name="category"
                        value="Events"
                        id="Events"
                        className="form-check-input me-1 "
                        onChange={(e) => handleChange(e)}
                        checked={venueDetials?.category === "Events"}
                        disabled
                      />
                      <label htmlFor="Events" className="CategoryText">
                        Events
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3">Venue Name*</div>
                <div className="col-12 parent">
                  <input
                    type="text"
                    className="form-control"
                    name="venueName"
                    value={venueDetials?.venueName}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required
                    autoComplete="off"
                    placeholder="Name of venue"
                  />
                  <span
                    className="form-text invalid-feedback"
                    style={{ fontSize: "14px" }}
                  >
                    * This field is Required!
                  </span>
                </div>
                <div className="col-12 mt-3">Description/About the Venue*</div>
                <div className="col-12 parent">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    name="description"
                    value={venueDetials?.description}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    autoComplete="off"
                    placeholder="About venue"
                    required
                  ></textarea>
                  <span
                    className="form-text invalid-feedback"
                    style={{ fontSize: "14px" }}
                  >
                    * This field is Required!
                  </span>
                </div>
                <div className="col-12 mt-3">Photos of the Venue*</div>
                <div className="col-12 my-3 d-flex flex-wrap">
                  <label htmlFor="filesInput">
                    <img
                      src="/images/UploadIamgeIcon.svg"
                      alt="logo"
                      className="cursor-pointer uploadImageIcon"
                    />
                  </label>
                  <input
                    type="file"
                    id="filesInput"
                    style={{ display: "none" }}
                    onChange={(e) => handleMultipleImagesUpload(e)}
                    name="venueimage"
                    multiple
                    accept=".jpg, .jpeg, .png"
                  />
                  {venueDetials?.venueImages?.length > 0 && (
                    <>
                      {venueDetials?.venueImages?.map((file, index) => (
                        <div className="col-4">
                          <div
                            key={index}
                            style={{ margin: "5px", position: "relative" }}
                          >
                            <img
                              className="img-fluid"
                              key={index}
                              src={file?.id === 0 ? file?.image : file?.image}
                              // src={file.Img}
                              alt="preview"
                              style={{
                                marginRight: "3px",
                                borderRadius: "5px",
                              }}
                            />
                            <img
                              src="/images/Closeicon.svg"
                              alt="close"
                              id={file?.id}
                              style={{
                                position: "absolute",
                                top: "1px",
                                right: "1px",
                                cursor: "pointer",
                                borderRadius: "13px",
                                background: "white",
                                padding: "2px",
                              }}
                              onClick={() =>
                                removeMultipleImages(index, file?.id)
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <span
                  className="text-danger d-none venueimageserror"
                  style={{ fontSize: "14px" }}
                >
                  * This field is Required!
                </span>
              </div>
            </div>
            <div className="text-start fw-semibold Montserrat pb-2 pt-3">
              Timing Schedule
            </div>
            <div className="">
              <div className="row Editblock mx-0 Montserrat fw-500 fs-14">
                <div className="col-12 mt-3">Operating Hours*</div>
                <div className="col-12">
                  {" "}
                  <div className="row">
                    <div className="col-md-4 col-4 my-1">
                      <input
                        type="radio"
                        name="customtime"
                        value="false"
                        id="false"
                        className="form-check-input me-1 "
                        onChange={(e) => handleChange(e)}
                        checked={venueDetials?.customtime === "false"}
                      />
                      <label htmlFor="false" className="CategoryText">
                        24 Hours
                      </label>
                    </div>
                    <div className="col-md-4 col-6 my-1">
                      <input
                        type="radio"
                        name="customtime"
                        value="true"
                        id="true"
                        className="form-check-input me-1 "
                        onChange={(e) => handleChange(e)}
                        checked={venueDetials?.customtime === "true"}
                      />
                      <label htmlFor="true" className="CategoryText">
                        Specific Hours
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <div className="row">
                    <div className="col-6">
                      <label
                        className="form-check-label  venueText"
                        htmlFor="customHours1"
                      >
                        Open Time*
                      </label>
                      <TimePicker
                        format={12}
                        start="00:00"
                        end="23:59"
                        step={60}
                        className="form-control"
                        name="startTime"
                        value={venueDetials?.startTime}
                        onChange={(time) => handleTimeChange(time, "startTime")}
                        disabled={venueDetials?.customtime === "false"}
                      />
                    </div>
                    <div className="col-6">
                      <label
                        className="form-check-label  venueText"
                        htmlFor="customHours1"
                      >
                        Close Time*
                      </label>
                      <TimePicker
                        format={12}
                        start="00:00"
                        end="23:59"
                        step={60}
                        className="form-control"
                        name="endTime"
                        value={venueDetials?.endTime}
                        onChange={(time) => handleTimeChange(time, "endTime")}
                        disabled={venueDetials?.customtime === "false"}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3">Minimum Booking Time*</div>
                <div className="col-8 col-md-4 mb-3 mt-1">
                  <div className="row align-items-center DurationDiv mx-0">
                    <div
                      className={`col-2 px-1 ${
                        slotDuration === 0.5
                          ? "DisableDurationBtn"
                          : "AddDurationBtn"
                      }`}
                      style={{
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        class={`cursor-pointer`}
                        onClick={(e) => {
                          if (slotDuration != 0.5) {
                            setSlotDuration(
                              (prevDuration) => prevDuration - 0.5
                            );
                          }
                        }}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>

                    <div className="col-8 p-0 text-center CategoryText">
                      {convertDecimalToTime(slotDuration)}
                    </div>

                    <div
                      className={`col-2 px-1 text-end ${
                        slotDuration === 5
                          ? "DisableDurationBtn"
                          : "AddDurationBtn"
                      }`}
                      style={{
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        class={`cursor-pointer`}
                        onClick={(e) => {
                          if (slotDuration < 5) {
                            setSlotDuration(
                              (prevDuration) => prevDuration + 0.5
                            );
                          }
                        }}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-start fw-semibold Montserrat pb-2 pt-3">
              Contact Details*
            </div>
            <div className="">
              <div className="row Editblock mx-0 Montserrat fw-500 fs-14">
                <div className="col-12 mt-3">Email</div>
                <div className="col-12">
                  {" "}
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={venueDetials?.email}
                    onBlur={(e) => ValidateEmail(e)}
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                    placeholder="Email"
                  />
                  <span
                    className="text-danger emailerrordiv d-none"
                    style={{ fontSize: "14px" }}
                  >
                    * Please Enter Valid Email!
                  </span>
                </div>
                <div className="col-12 mt-3">
                  Phone No.* <small>(Please use whatsapp no.)</small>
                </div>
                <div className="col-12 parent">
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control"
                    name="phone"
                    value={venueDetials?.phone}
                    onChange={(e) => handleChange(e)}
                    maxLength={10}
                    placeholder="Phone number"
                    required
                  />
                  <span
                    className="form-text invalid-feedback"
                    style={{ fontSize: "14px" }}
                  >
                    * This field is Required!
                  </span>
                  <span
                    className="d-none phoneerrordiv text-danger"
                    style={{ fontSize: "14px" }}
                  >
                    * Please Enter 10 Digit Number!
                  </span>
                </div>
                <div className="col-12 mt-3">
                  Street Address*
                  <small>
                    ( Please give precise address as it will be used to provide
                    directions )
                  </small>
                </div>
                <div className="col-12 parent">
                  <input
                    type="text"
                    className="form-control"
                    name="addressline1"
                    value={venueDetials?.address?.addressline1}
                    onChange={(e) => handleChange(e, true)}
                    autoComplete="off"
                    placeholder="Street address"
                    required
                  />
                  <span
                    className="form-text invalid-feedback"
                    style={{ fontSize: "14px" }}
                  >
                    * This field is Required!
                  </span>
                </div>
                <div className="col-12 mt-3">Landmark</div>
                <div className="col-12 ">
                  <input
                    type="text"
                    className="form-control"
                    name="addressline3"
                    value={venueDetials?.address?.addressline3}
                    onChange={(e) => handleChange(e, true)}
                    autoComplete="off"
                    placeholder="Near by landmark(optional)"
                  />
                </div>
                <div className="col-12 my-3">
                  <div className="row">
                    <div className="col-6 parent">
                      Zip Code*
                      <input
                        type="number"
                        inputMode="numeric"
                        className="form-control"
                        name="zipcode"
                        value={venueDetials?.address?.zipcode}
                        onChange={(e) => handleZipCode(e)}
                        placeholder="Pin code"
                        required
                      />
                      <span
                        className="form-text invalid-feedback"
                        style={{ fontSize: "14px" }}
                      >
                        * This field is Required!
                      </span>
                      <span
                        className="d-none text-danger zipinvaliderrordiv"
                        style={{ fontSize: "14px" }}
                      >
                        * Invalid Zip Code!
                      </span>
                    </div>
                    <div className="col-6 parent">
                      Area/Locality*
                      <Select
                        name="addressline2"
                        id="addressline2"
                        value={selectedArea}
                        isSearchable="true"
                        options={areaOptions}
                        menuPlacement="auto"
                        isDisabled={areaOptions?.length === 0}
                        onChange={(selectedOption) => {
                          setVenueDetails((prevDetails) => ({
                            ...prevDetails,
                            address: {
                              ...prevDetails.address,
                              ["addressline2"]: selectedOption.label,
                            },
                          }));
                        }}
                      />
                      {areaRequired && (
                        <small
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          * This field is Required!
                        </small>
                      )}
                    </div>
                    <div className="col-6 mt-3">
                      City*
                      <input
                        className="form-control"
                        aria-label="Select City"
                        name="cityId"
                        value={venueDetials?.address?.cityId}
                        placeholder="City name"
                        onChange={(e) => handleChange(e, true)}
                        type="text"
                        disabled={areaOptions?.length === 0}
                      />
                    </div>
                    <div className="col-6 mt-3 parent">
                      State*
                      <input
                        className="form-control"
                        aria-label="Select State"
                        name="stateId"
                        value={venueDetials?.address?.stateId}
                        placeholder="State name"
                        onChange={(e) => handleChange(e, true)}
                        type="text"
                        disabled
                        required
                      />
                      <span
                        className="form-text invalid-feedback"
                        style={{ fontSize: "14px" }}
                      >
                        * This field is Required!
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-start fw-semibold Montserrat pb-2 pt-3">
              Cancellation And Reschedule Policy*
            </div>
            <div className="">
              <div className="row Editblock mx-0 Montserrat fw-500 fs-14">
                <div className="col-12 mt-3">
                  Cancellation Policy* <br />
                  <small style={{ fontSize: "10px" }}>
                    ( How many hours in prior User can cancel the booking? )
                  </small>
                </div>
                <div className="col-12 parent">
                  {" "}
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control"
                    placeholder="Cancellation time"
                    name="cancellationTime"
                    value={venueDetials?.cancellationTime}
                    onChange={(e) => handleChange(e)}
                    max="99"
                    inputMode="numeric"
                    autoComplete="off"
                    required
                  />
                  <span
                    className="form-text invalid-feedback"
                    style={{ fontSize: "14px" }}
                  >
                    * This field is Required!
                  </span>
                </div>
                <div className="col-12 mt-3">
                  Reschedule Hours* <br />
                  <small style={{ fontSize: "10px" }}>
                    ( How many hours in prior User can reschedule the booking? )
                  </small>
                </div>
                <div className="col-12 parent">
                  {" "}
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control"
                    placeholder="Reschedule Hours"
                    name="rescheduleHours"
                    value={venueDetials?.rescheduleHours}
                    onChange={(e) => handleChange(e)}
                    max="99"
                    inputMode="numeric"
                    autoComplete="off"
                    required
                  />
                  <span
                    className="form-text invalid-feedback"
                    style={{ fontSize: "14px" }}
                  >
                    * This field is Required!
                  </span>
                </div>
                <div className="col-12 mt-3">
                  Reschedule Count* <br />
                  <small style={{ fontSize: "10px" }}>
                    ( How many time User can reschedule the booking? )
                  </small>
                </div>
                <div className="col-12 mb-3 parent">
                  {" "}
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control"
                    placeholder="Reschedule Count"
                    name="rescheduleCount"
                    value={venueDetials?.rescheduleCount}
                    onChange={(e) => handleChange(e)}
                    max="99"
                    inputMode="numeric"
                    autoComplete="off"
                    required
                  />
                  <span
                    className="form-text invalid-feedback"
                    style={{ fontSize: "14px" }}
                  >
                    * This field is Required!
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-center">
              <button
                className="EditVenueSaveBtn"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </>
    </React.Fragment>
  );
}
